import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
import { getUserData, getUserInfo } from '@/api/user';
export default {
  components: {},
  data() {
    return {
      name: '',
      avatar: '',
      index: 0,
      ditemTime1: '最近一周',
      ditemTime2: '最近一周',
      ditemTime3: '最近一周',
      show: false,
      actions: [{
        name: '最近一周'
      }, {
        name: '最近一月'
      }, {
        name: '近半年'
      }],
      arr1: [],
      arr2: [],
      arr3: []
    };
  },
  created() {
    if (!getToken()) {
      this.$router.push('/login');
      return;
    }
    this.arr1 = [];
    this.arr2 = [];
    this.arr3 = [];
    for (var i = 0; i < 6; i++) {
      this.arr1.push([0, 0]);
    }
    for (var i = 0; i < 3; i++) {
      this.arr2.push([0, 0]);
    }
    for (var i = 0; i < 3; i++) {
      this.arr3.push([0, 0]);
    }
    this.id = this.$route.query.id;
    this.vGetUserInfo();
    this.getData(0);
    this.index = 1;
    this.getData(0);
    this.index = 2;
    this.getData(0);
  },
  methods: {
    vGetUserInfo() {
      getUserInfo({
        id: this.id
      }).then(resp => {
        this.name = resp.data.name;
        this.avatar = resp.data.avatar;
      });
    },
    vSelectTime(index) {
      this.index = index;
      this.show = true;
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      if (this.index == 2) {
        this.ditemTime3 = item.name;
      } else if (this.index == 1) {
        this.ditemTime2 = item.name;
      } else {
        this.ditemTime1 = item.name;
      }
      this.show = false;
      let currIndex = 0;
      for (var i = 0; i < this.actions.length; i++) {
        if (this.actions[i].name == item.name) {
          currIndex = i;
          break;
        }
      }
      this.getData(currIndex);
    },
    getData(timeIndex) {
      let param = {
        id: this.id,
        type: this.index,
        index: timeIndex
      };
      getUserData(param).then(resp => {
        if (resp.data.type == 2) {
          this.arr3 = resp.data.data;
        } else if (resp.data.type == 1) {
          this.arr2 = resp.data.data;
        } else {
          this.arr1 = resp.data.data;
        }
      });
    }
  }
};