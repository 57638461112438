import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
import { getInfo } from '@/api/user';
export default {
  name: 'Home',
  title: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      isMing: false,
      show: true,
      phone: '',
      sum1: '0.00',
      sum2: '0.00',
      headimgurl: ''
    };
  },
  created() {
    if (!getToken()) {
      console.log('no token');
      this.$router.push('login');
      return;
    }
    let uinfo = JSON.parse(localStorage.getItem("xinguserInfo"));
    this.isMing = uinfo.isMing ? true : false;
    // this.isMing = false;
    //
    this.headimgurl = uinfo.headimgurl;
    // 手机号码脱敏处理:方法1
    let phone = uinfo.phone;
    let str = phone.substring(0, 3) + "****" + phone.substring(7);
    this.phone = str;
    getInfo().then(resp => {
      console.log(resp);
      this.sum1 = resp.data.sum1.toFixed(2);
      this.sum2 = resp.data.sum2.toFixed(2);
    });
  },
  methods: {
    toCharge() {
      _Toast('暂未开放');
    },
    toGetMoney() {
      _Toast('暂未开放');
    },
    jump() {
      this.$router.push('orderlist');
    },
    jump2() {
      this.$router.push('shiming');
    },
    jump3() {
      this.$router.push('zhongcai');
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    }
  }
};