var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_vm.loginBy ? _c('div', {
    staticClass: "txt1"
  }, [_vm._v("输入登录密码")]) : _c('div', {
    staticClass: "txt1"
  }, [_vm._v("输入验证码")]), _vm.loginBy ? _c('div', {
    staticClass: "txt2"
  }, [_vm._v("欢迎来到信而签！"), _c('span', {
    staticStyle: {
      "color": "skyblue"
    },
    on: {
      "click": _vm.changeLogin
    }
  }, [_vm._v("验证码登录")])]) : _c('div', {
    staticClass: "txt2"
  }, [_vm._v("欢迎来到信而签！"), _c('span', {
    staticStyle: {
      "color": "skyblue"
    },
    on: {
      "click": _vm.changeLogin
    }
  }, [_vm._v("密码登录")])]), _vm.loginBy ? _c('van-field', {
    staticClass: "field",
    attrs: {
      "type": "password",
      "placeholder": "请输入登录密码"
    },
    model: {
      value: _vm.tel,
      callback: function ($$v) {
        _vm.tel = $$v;
      },
      expression: "tel"
    }
  }) : _vm._e(), !_vm.loginBy ? _c('van-field', {
    staticClass: "field",
    attrs: {
      "maxlength": "6",
      "name": "code",
      "placeholder": "请输入验证码"
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function () {
        return [_vm.timerTf ? _c('van-button', {
          attrs: {
            "native-type": "button",
            "size": "small",
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.receiveCode();
            }
          }
        }, [_vm._v(" 发送验证码")]) : _c('van-button', {
          attrs: {
            "disabled": "",
            "native-type": "button",
            "size": "small",
            "type": "primary"
          }
        }, [_vm._v("重新获取(" + _vm._s(_vm.countDownNum) + ")秒 ")])];
      },
      proxy: true
    }], null, false, 2046545128),
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }) : _vm._e(), _c('van-button', {
    staticClass: "btn",
    attrs: {
      "loading": _vm.loading,
      "loading-text": "登录中...",
      "disabled": _vm.btnStatus,
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("登录")]), _c('div', {
    staticStyle: {
      "text-align": "right",
      "width": "100%"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/resetpwd"
    }
  }, [_c('van-button', {
    attrs: {
      "size": "small"
    }
  }, [_vm._v("忘记密码？")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };