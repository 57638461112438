var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("对方姓名" + _vm._s(_vm.list.isbuyer == 1 ? '(卖方)' : '(买方)'))])]), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.toBuy();
      }
    }
  }, [_c('div', {
    staticStyle: {
      "color": "#999"
    }
  }, [_vm._v(_vm._s(!_vm.list.name ? '填写正确才能生效哦' : _vm.list.name))]), _c('van-icon', {
    attrs: {
      "name": "arrow",
      "color": "#999"
    }
  })], 1), _vm.list.type == 51 ? [_vm._m(0), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.item,
      callback: function ($$v) {
        _vm.$set(_vm.list, "item", $$v);
      },
      expression: "list.item"
    }
  })], 1)] : _vm._e(), _vm._m(1), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.price,
      callback: function ($$v) {
        _vm.$set(_vm.list, "price", $$v);
      },
      expression: "list.price"
    }
  })], 1), _vm.list.type == 53 || _vm.list.type == 54 ? [_vm._m(2), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.earnestMoney,
      callback: function ($$v) {
        _vm.$set(_vm.list, "earnestMoney", $$v);
      },
      expression: "list.earnestMoney"
    }
  })], 1), _vm._m(3), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.address,
      callback: function ($$v) {
        _vm.$set(_vm.list, "address", $$v);
      },
      expression: "list.address"
    }
  })], 1), _vm._m(4), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.area,
      callback: function ($$v) {
        _vm.$set(_vm.list, "area", $$v);
      },
      expression: "list.area"
    }
  })], 1)] : _vm._e(), _vm.list.type == 52 || _vm.list.type == 53 ? [_vm._m(5), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.productName,
      callback: function ($$v) {
        _vm.$set(_vm.list, "productName", $$v);
      },
      expression: "list.productName"
    }
  })], 1), _vm._m(6), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.year,
      callback: function ($$v) {
        _vm.$set(_vm.list, "year", $$v);
      },
      expression: "list.year"
    }
  })], 1)] : _vm._e(), _vm.list.type == 11 ? [_vm._m(7), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.desc,
      callback: function ($$v) {
        _vm.$set(_vm.list, "desc", $$v);
      },
      expression: "list.desc"
    }
  })], 1)] : _vm._e(), _vm.list.type == 12 || _vm.list.type == 13 ? [_vm._m(8), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.mileage,
      callback: function ($$v) {
        _vm.$set(_vm.list, "mileage", $$v);
      },
      expression: "list.mileage"
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "upBtn"
  }, [_c('div', {
    staticClass: "left-btn"
  }, [_c('van-checkbox', {
    attrs: {
      "checked-color": "#688DFF"
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }), _c('div', {
    staticStyle: {
      "color": "#999",
      "margin-left": "5px"
    }
  }, [_vm._v("已阅读并同意")]), _c('div', {
    staticStyle: {
      "color": "#688DFF"
    },
    on: {
      "click": function ($event) {
        return _vm.toPdf();
      }
    }
  }, [_vm._v("《相关协议》")])], 1), !_vm.checked ? _c('div', {
    staticClass: "addBtn"
  }, [_vm._v(" 提交 ")]) : _c('div', {
    staticClass: "addBtn checkBtn",
    on: {
      "click": function ($event) {
        return _vm.up();
      }
    }
  }, [_vm._v(" 提交 ")])]), _c('van-action-sheet', {
    attrs: {
      "title": "交易详情"
    },
    model: {
      value: _vm.actionSheetShow,
      callback: function ($$v) {
        _vm.actionSheetShow = $$v;
      },
      expression: "actionSheetShow"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "交易账户",
      "value": _vm.phone
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付方式",
      "value": _vm.payways[_vm.payindex],
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        _vm.paywayShow = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付金额",
      "value": _vm.vShowMoney
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toPay();
      }
    }
  }, [_vm._v("确定支付")])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.paywayShow,
      expression: "paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "微信支付",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(0);
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "余额支付",
      "value": "余额不足",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(1);
      }
    }
  })], 1), _c('van-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.toPay
    }
  }, [_vm._v("确定支付 ")])], 1)])], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("物品")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("总价格(元)")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("定金(元)")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("地址")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("面积")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("产品介绍")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("年限")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("车辆概况")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("行驶里程")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}];
export { render, staticRenderFns };