/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./carrer.vue?vue&type=template&id=9861ca90&scoped=true&"
import script from "./carrer.vue?vue&type=script&lang=js&"
export * from "./carrer.vue?vue&type=script&lang=js&"
import style0 from "./carrer.vue?vue&type=style&index=0&id=9861ca90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9861ca90",
  null
  
)

export default component.exports