import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
import img1 from '@/assets/renz/renz_img1.jpg';
import { postShiming, getCode } from '@/api/user';
import { getToken } from '@/utils/auth';
export default {
  components: {
    [_Dialog.Component.name]: _Dialog.Component
  },
  created() {
    if (!getToken()) {
      this.$router.push('login');
      return;
    }
    let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
    if (!uInfo.phone) {
      this.$router.push('login');
      return;
    }
    this.value3 = uInfo.phone;
  },
  data() {
    return {
      img1,
      loading: false,
      countDownNum: 60,
      timerTf: true,
      timer: null,
      username: '',
      value4: '',
      value5: '',
      value1: '',
      value2: '',
      value3: '',
      pattern: /\d{17}([0-9]|x|X)/
    };
  },
  methods: {
    torelogin() {
      let that = this;
      this.$store.dispatch('user/logout', {}).then(() => {
        // this.$router.push({
        // 	path: this.redirect || '/'
        // })
        that.$router.replace('/my');
        console.log('logut then');
      }).catch(e => {
        console.log(e);
        console.log('catch');
      });
    },
    receiveCode() {
      let params = {
        phone: this.value3
      };
      getCode(params).then(resp => {
        if (resp.code == 200) {
          _Toast('发送成功');
          this.timerTf = false;
          this.countDownNum = 60;
          this.timer = setInterval(() => {
            this.countDownNum--;
            if (this.countDownNum <= 0) {
              this.timerTf = true;
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          _Toast('提示内容');
        }
      });
    },
    validator(val) {
      return /1\d{10}/.test(val);
    },
    onSubmit(values) {
      console.log('submit', values);
      _Dialog.confirm({
        title: '提示',
        message: '请确认填写的姓名，身份证号为您本人信息，且真实有效，信息确认后，不支持修改'
      }).then(() => {
        // on confirm
        this.loading = true;
        let postData = values;
        postShiming(postData).then(resp => {
          this.loading = false;
          if (resp.code == 200) {
            let uinfo = JSON.parse(localStorage.getItem('xinguserInfo'));
            uinfo = Object.assign({}, uinfo, values);
            localStorage.setItem('xinguserInfo', JSON.stringify(uinfo));
            this.$router.push('shiming2');
          } else {
            _Toast(resp.message);
          }
        }).catch(() => {
          this.loading = false;
        });
      }).catch(() => {
        // on cancel
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    }
  }
};