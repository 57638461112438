var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(51);
      }
    }
  }, [_vm._v(" 赠与合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 赠与通用场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(52);
      }
    }
  }, [_vm._v(" 健身会员合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于健身俱乐部入会的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(53);
      }
    }
  }, [_vm._v(" 装饰装修合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于装修的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(54);
      }
    }
  }, [_vm._v(" 家政服务合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于聘请家教场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };