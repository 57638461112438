var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(31);
      }
    }
  }, [_vm._v(" 员工竞业限制和保密合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于员工入职的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(32);
      }
    }
  }, [_vm._v(" 员工保密合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 员工保密合同 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(33);
      }
    }
  }, [_vm._v(" 劳务兼职合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于劳务兼职的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(34);
      }
    }
  }, [_vm._v(" 劳务合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于劳务合作场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };