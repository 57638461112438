var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home"
  }, [_c('div', {
    staticClass: "top-bg2"
  }, [_c('van-tabs', {
    attrs: {
      "color": "#FFFFFF",
      "background": "#4098fc",
      "type": "line",
      "title-inactive-color": "#ddd",
      "title-active-color": "#ffffff"
    },
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "title": "可仲裁"
    }
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1), _c('van-tab', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "title": "仲裁中"
    }
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1), _c('van-tab', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "title": "已出裁决"
    }
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1), _c('van-tab', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "title": "已完结"
    }
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };