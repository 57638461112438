var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "txt1"
  }, [_vm._v("设置登录密码")]), _c('van-field', {
    staticClass: "field",
    attrs: {
      "type": "password",
      "placeholder": "请输入登录密码",
      "required": ""
    },
    model: {
      value: _vm.pwd,
      callback: function ($$v) {
        _vm.pwd = $$v;
      },
      expression: "pwd"
    }
  }), _c('van-field', {
    staticClass: "field",
    attrs: {
      "type": "password",
      "placeholder": "请再次输入密码",
      "required": ""
    },
    model: {
      value: _vm.pwd2,
      callback: function ($$v) {
        _vm.pwd2 = $$v;
      },
      expression: "pwd2"
    }
  }), _c('van-button', {
    staticClass: "btn",
    attrs: {
      "disabled": _vm.pwd.length > 5 ? false : true,
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("下一步")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };