import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { delUser } from '@/api/user';
export default {
  data() {
    return {
      showSafePwd: false,
      safePwd: ''
    };
  },
  methods: {
    afterSafePwd() {
      delUser({
        pwd: this.safePwd
      }).then(() => {
        this.showSafePwd = false;
        // this.$store.re
        _Toast('销户成功');
        this.logout();
      });
    },
    vXiaoFu() {
      _Dialog.confirm({
        title: '警告',
        message: '账户一经注销，将删除账户一切信息，包含登录、借条、请谨慎操作！！！'
      }).then(() => {
        // on close
        this.showSafePwd = true;
      });
    },
    logout() {
      let that = this;
      this.$store.dispatch('user/logout', {}).then(() => {
        // this.$router.push({
        // 	path: this.redirect || '/'
        // })
        that.$router.replace('/');
        console.log('logut then');
      }).catch(e => {
        console.log(e);
        console.log('catch');
      });
    }
  }
};