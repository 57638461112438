var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4097fe",
      "height": "2rem"
    }
  }), _c('div', {
    staticClass: "items"
  }, [_c('van-row', [_c('van-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.tolist(1);
      }
    }
  }, [_c('van-image', {
    attrs: {
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.img1
    }
  }), _c('div', {
    staticClass: "txt"
  }, [_vm._v("交易消息")])], 1)]), _c('van-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.tolist(2);
      }
    }
  }, [_c('van-image', {
    attrs: {
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.img2
    }
  }), _c('div', {
    staticClass: "txt"
  }, [_vm._v("借条消息")])], 1)]), _c('van-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.tolist(3);
      }
    }
  }, [_c('van-image', {
    attrs: {
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.img3
    }
  }), _c('div', {
    staticClass: "txt"
  }, [_vm._v("仲裁消息")])], 1)]), _c('van-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.tolist(4);
      }
    }
  }, [_c('van-image', {
    attrs: {
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.img4
    }
  }), _c('div', {
    staticClass: "txt"
  }, [_vm._v("系统消息")])], 1)])], 1)], 1), _vm.noData ? _c('div', {
    staticClass: "p30 text-center fontSize30"
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1) : _vm._e(), _c('van-pull-refresh', {
    staticClass: "content",
    attrs: {
      "success-text": "刷新成功"
    },
    on: {
      "refresh": _vm.onRefresh
    },
    model: {
      value: _vm.isLoading,
      callback: function ($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  }, [_vm.contractData.length > 0 ? _c('van-list', {
    attrs: {
      "finished": _vm.finished,
      "immediate-check": false,
      "finished-text": "我也是有底线的",
      "offset": 10
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.contractData, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "van-hairline--left item",
      on: {
        "click": function ($event) {
          return _vm.itemClick(item.id);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('van-icon', {
      staticStyle: {
        "margin-right": "0.2rem"
      },
      attrs: {
        "name": "volume-o",
        "color": "red"
      }
    }), item.type == 1 ? _c('span', {
      staticClass: "title"
    }, [_vm._v("服务费-补借条")]) : _vm._e(), item.type == 2 ? _c('span', {
      staticClass: "title"
    }, [_vm._v("服务费-延期借条")]) : _vm._e(), _c('span', {
      staticStyle: {
        "color": "red",
        "flex-grow": "1",
        "text-align": "right"
      }
    }, [_vm._v("申请待确认")])], 1), _c('div', {
      staticStyle: {
        "color": "#666",
        "text-align": "center",
        "font-size": "0.3rem",
        "padding": "0.2rem"
      }
    }, [_vm._v("交易金额")]), _c('div', {
      staticStyle: {
        "color": "#333",
        "text-align": "center",
        "font-size": "0.5rem",
        "padding": "0.2rem",
        "margin-bottom": "0.2rem"
      }
    }, [_vm._v("￥" + _vm._s(item.num / 100))]), _c('div', [_vm._v("您的借条服务费支付成功")]), _c('van-divider'), _c('div', {
      staticStyle: {
        "display": "flex",
        "margin-top": "0.3rem"
      }
    }, [_c('div', [_vm._v("交易时间：" + _vm._s(_vm.$dayjs(item.create_time).format('YYYY-MM-DD')))])])], 1);
  }), 0) : _vm._e()], 1), _c('van-tabbar', {
    attrs: {
      "route": ""
    }
  }, [_c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/",
      "icon": "home-o"
    }
  }, [_vm._v("首页")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/orderlist",
      "icon": "after-sale"
    }
  }, [_vm._v("合同")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/msg",
      "icon": "chat-o"
    }
  }, [_vm._v("消息")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/my",
      "icon": "user-o"
    }
  }, [_vm._v("我的")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };