import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { getInvoicingContractList } from '@/api/user';
import { delOrderById } from '@/api/order';
import { getToken } from '@/utils/auth';
export default {
  name: 'OrderList',
  title: '借条管理',
  components: {
    // HelloWorld
  },
  computed: {
    // console.log(dayjs('2021-10-19').diff('2021-10-18', 'day'), '两个日期之间相差的天数')
  },
  data() {
    return {
      keyword: '',
      show: true,
      zwdd: require("@/assets/logo.png"),
      titleName: "我要开票",
      arrowTrue: true,
      page: 1,
      pageSize: 10,
      isLoading: false,
      loading: false,
      finished: false,
      noData: false,
      contractData: [],
      timer: null,
      active: 0,
      type: 1,
      sort: 0,
      moneySortDesc: 1,
      timeSortDesc: 1
    };
  },
  created() {
    var _this$$route$query$ty;
    if (!getToken()) {
      this.$router.push('login');
      return;
    }
    this.type = (_this$$route$query$ty = this.$route.query.type) !== null && _this$$route$query$ty !== void 0 ? _this$$route$query$ty : 1;
    // this.$nextTick(()=>{
    this.active = Number(this.$route.query.status) ? Number(this.$route.query.status) : 0;

    // })

    //方法调用
    this.getInvoicingContractListFun();
  },
  mounted() {
    // this.active = this.$route.query.status ?? 0
    // console.log(this.active)
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    onSearch(val) {
      this.keyword = val;
      this.sort = 0;
      this.resetList();
      this.getInvoicingContractListFun();
    },
    onCancel() {
      // Toast('取消');
      this.keyword = '';
      this.sort = 0;
      this.resetList();
      this.getInvoicingContractListFun();
    },
    vDelTiao(id) {
      let that = this;
      _Dialog.confirm({
        title: '请确认',
        message: '确认删除该借条信息吗？删除后无法恢复'
      }).then(() => {
        // on confirm
        delOrderById(id).then(() => {
          _Toast('删除成功');
          that.contractData = [];
          that.getInvoicingContractListFun();
        });
      }).catch(() => {
        // on cancel
      });
    },
    resetList() {
      this.show = true;
      this.contractData = [];
      this.page = 1;
    },
    tabChange(name, title) {
      this.sort = 0;
      this.resetList();
      this.getInvoicingContractListFun();
    },
    goNext(sn) {
      this.$router.push('orderinfo?sn=' + sn);
    },
    timeCompute(end_time) {
      console.log(this.active);
      if (this.active == 0) {
        return '待确认';
      } else if (this.active == 3) {
        return '已收款';
      } else if (this.active == 4) {
        return '已取消';
      }
      //end_time = 1674576000
      let num = this.$dayjs(this.$dayjs(end_time * 1000).format("YYYY-MM-DD")).diff(this.$dayjs(), 'day');
      console.log(num);
      if (num == 0) {
        num = this.$dayjs(this.$dayjs(end_time * 1000 + 86400000).format("YYYY-MM-DD")).diff(this.$dayjs(), 'day');
        if (num == 0) {
          return '<van-icon name="balance-list-o">今日还款</van-icon>';
        }
        return '<span>还剩<span style="padding-left:0.2rem;padding-right:0.2rem;color:green;font-weight: bold;">' + 1 + '</span>天</span>';
      } else if (num > 0) {
        num = num + 1;
        return '<span>还剩<span style="padding-left:0.2rem;padding-right:0.2rem;color:green;font-weight: bold;">' + num + '</span>天</span>';
      } else if (num == 0) {
        return '<van-icon name="balance-list-o">今日还款</van-icon>';
      } else {
        return '<span>逾期<span style="color:red;padding-left:0.2rem;padding-right:0.2rem;">' + -num + '</span>天</span>';
      }
    },
    // 数据列表方法
    getInvoicingContractListFun() {
      let desc;
      if (this.sort == 1) {
        desc = this.moneySortDesc;
      } else if (this.sort == 2) {
        desc = this.timeSortDesc;
      } else {
        desc = 1;
      }
      let params = {
        type: this.type,
        sort: this.sort,
        desc: desc,
        status: this.active,
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword
      };
      console.log(params);
      getInvoicingContractList(params).then(res => {
        this.show = false;
        if (res.code == 200) {
          console.log(this.contractData);
          if (this.contractData.length > 0) {
            this.noData = false;
            //当请求前有数据时 第n次请求
            if (this.loading) {
              // 上拉加载
              this.contractData = this.contractData.concat(res.data.data); //上拉加载新数据添加到数组中
              this.$nextTick(() => {
                //在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false; //关闭上拉加载中
              });

              if (res.data.data.length == 0) {
                //没有更多数据
                this.finished = true; //上拉加载完毕
              }
            }

            if (this.isLoading) {
              //关闭下拉刷新
              this.isLoading = false; //关闭下拉刷新中
              this.contractData = res.data.data; //重新给数据赋值
              if (this.finished) {
                //如果上拉加载完毕为true则设为false。解决上拉加载完毕后再下拉刷新就不会执行上拉加载问题
                this.finished = false;
              }
            }
          } else {
            this.noData = false;
            this.loading = false;
            this.isLoading = false;
            this.finished = false;
            //当请求没有数据时 第一次请求
            if (res.data.data.length == 0) {
              this.noData = true;
            } else {
              this.contractData = res.data.data;
            }
            console.log(123);
          }
        } else {
          this.$toast(res.msg);
        }
      }).catch(error => {
        console.log(error);
        this.show = false;
      });
    },
    // 刷新
    onRefresh() {
      this.isLoading = true;
      this.page = 1;
      if (this.contractData.length == 0) {
        this.isLoading = false;
      }
      this.getInvoicingContractListFun();
    },
    // 加载更多
    onLoad() {
      this.loading = true;
      this.timer = setTimeout(() => {
        this.page++;
        this.getInvoicingContractListFun();
      }, 1000);
    },
    changeSort(sort) {
      if (this.sort == sort && sort == 1) {
        this.moneySortDesc = 1 - this.moneySortDesc;
      } else if (this.sort == sort && sort == 2) {
        this.timeSortDesc = 1 - this.timeSortDesc;
      } else {
        this.sort = sort;
      }
      this.resetList();
      this.getInvoicingContractListFun();
    },
    changeType(type) {
      this.type = type;
      this.sort = 0;
      this.resetList();
      this.getInvoicingContractListFun();
    }
  }
};