/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./changeBuy.vue?vue&type=template&id=30b8426c&scoped=true&"
import script from "./changeBuy.vue?vue&type=script&lang=js&"
export * from "./changeBuy.vue?vue&type=script&lang=js&"
import style0 from "./changeBuy.vue?vue&type=style&index=0&id=30b8426c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b8426c",
  null
  
)

export default component.exports