var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-image', {
    attrs: {
      "src": _vm.img1
    }
  }), _c('van-form', {
    attrs: {
      "validate-first": ""
    },
    on: {
      "failed": _vm.onFailed,
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "name": "mail",
      "label": "邮箱",
      "placeholder": "邮箱",
      "rules": [{
        validator: _vm.validator,
        message: '请填写邮箱'
      }]
    },
    model: {
      value: _vm.value1,
      callback: function ($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "0.2rem"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#a1a1a1"
    }
  }, [_vm._v("请填写本人真实有效的邮箱地址，该邮箱将用于接收平台给您发送的邮件。")])]), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("完成认证")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };