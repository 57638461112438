var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', _vm._l(_vm.pageTotal, function (item) {
    return _c('pdf', {
      key: item,
      attrs: {
        "src": _vm.pdfUrl,
        "page": item
      }
    });
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };