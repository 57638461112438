var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "txt1"
  }, [_vm._v("注册/登录 信而签")]), _c('div', {
    staticClass: "txt2"
  }, [_vm._v("欢迎来到信而签，注册认证即可打借条")]), _c('van-field', {
    staticClass: "field",
    attrs: {
      "type": "tel",
      "placeholder": "请输入手机号"
    },
    model: {
      value: _vm.tel,
      callback: function ($$v) {
        _vm.tel = $$v;
      },
      expression: "tel"
    }
  }), _c('van-button', {
    staticClass: "btn",
    attrs: {
      "disabled": _vm.btnStatus,
      "type": "info"
    },
    on: {
      "click": _vm.goNext
    }
  }, [_vm._v("下一步")]), _c('div', {
    staticClass: "txt3"
  }, [_vm._v("注册登录代表您已同意信而签"), _c('router-link', {
    attrs: {
      "to": "rule?type=1"
    }
  }, [_c('span', [_vm._v("《用户协议》")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };