// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { getInvoicingContractList } from '@/api/user';
export default {
  name: 'OrderList',
  title: '借条管理',
  components: {
    // HelloWorld
  },
  data() {
    return {
      zwdd: require("@/assets/logo.png"),
      titleName: "我要开票",
      arrowTrue: true,
      page: 1,
      pageSize: 10,
      isLoading: false,
      loading: false,
      finished: false,
      noData: false,
      contractData: [],
      timer: null,
      active: 0,
      type: 1,
      sort: 0
    };
  },
  created() {
    //方法调用
    this.getInvoicingContractListFun();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    // 数据列表方法
    getInvoicingContractListFun() {
      let params = {
        carrierUserId: this.id,
        page: this.page,
        pageSize: this.pageSize
      };
      getInvoicingContractList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          res.data.data = [];
          if (this.contractData.length > 0) {
            this.noData = false;
            //当请求前有数据时 第n次请求
            if (this.loading) {
              // 上拉加载
              this.contractData = this.contractData.concat(res.data.data); //上拉加载新数据添加到数组中
              this.$nextTick(() => {
                //在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false; //关闭上拉加载中
              });

              if (res.data.data.length == 0) {
                //没有更多数据
                this.finished = true; //上拉加载完毕
              }
            }

            if (this.isLoading) {
              //关闭下拉刷新
              this.isLoading = false; //关闭下拉刷新中
              this.contractData = res.data.data; //重新给数据赋值
              if (this.finished) {
                //如果上拉加载完毕为true则设为false。解决上拉加载完毕后再下拉刷新就不会执行上拉加载问题
                this.finished = false;
              }
            }
          } else {
            this.noData = false;
            this.loading = false;
            this.isLoading = false;
            this.finished = false;
            //当请求没有数据时 第一次请求
            if (res.data.data.length == 0) {
              this.noData = true;
            } else {
              this.contractData = res.data.data;
            }
            console.log(123);
          }
        } else {
          this.$toast(res.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // 刷新
    onRefresh() {
      this.isLoading = true;
      this.page = 1;
      if (this.contractData.length == 0) {
        this.isLoading = false;
      }
      this.getInvoicingContractListFun();
    },
    // 加载更多
    onLoad() {
      this.loading = true;
      this.timer = setTimeout(() => {
        this.page++;
        this.getInvoicingContractListFun();
      }, 1000);
    },
    changeSort(sort) {
      this.sort = sort;
    },
    changeType(type) {
      this.type = type;
    }
  }
};