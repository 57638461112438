import "core-js/modules/es.array.push.js";
import { getInvoicingContractList } from '@/api/msg';
export default {
  name: 'Home',
  title: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      arrowTrue: true,
      page: 1,
      pageSize: 10,
      isLoading: false,
      loading: false,
      finished: false,
      noData: false,
      contractData: [],
      timer: null
    };
  },
  created() {
    var _this$$route$query$ty;
    let type = (_this$$route$query$ty = this.$route.query.type) !== null && _this$$route$query$ty !== void 0 ? _this$$route$query$ty : 1;
    this.type = type;
    //方法调用
    this.getInvoicingContractListFun();
    if (type == 1) document.title = '交易消息';else if (type == 2) document.title = '借条消息';else if (type == 3) document.title = '仲裁消息';else if (type == 4) document.title = '系统消息';
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    itemClick(id) {
      this.$router.push('msginfo?id=' + id);
    },
    timeCompute(pay_time) {
      // pay_time = 1670836556
      let num = this.$dayjs(this.$dayjs(pay_time * 1000).format("YYYY-MM-DD")).diff(this.$dayjs(), 'day');
      if (num > 0) {
        return '<span>还剩<span style="padding-left:0.2rem;padding-right:0.2rem;">' + num + '</span>天</span>';
      } else if (num == 0) {
        return '<van-icon name="balance-list-o">今日还款</van-icon>';
      } else {
        return '<span>逾期<span style="color:red;padding-left:0.2rem;padding-right:0.2rem;">' + -num + '</span>天</span>';
      }
    },
    // 数据列表方法
    getInvoicingContractListFun() {
      let params = {
        type: this.type,
        page: this.page,
        pageSize: this.pageSize
      };
      getInvoicingContractList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          console.log(this.contractData);
          if (this.contractData.length > 0) {
            this.noData = false;
            //当请求前有数据时 第n次请求
            if (this.loading) {
              // 上拉加载
              this.contractData = this.contractData.concat(res.data.data); //上拉加载新数据添加到数组中
              this.$nextTick(() => {
                //在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false; //关闭上拉加载中
              });

              if (res.data.data.length == 0) {
                //没有更多数据
                this.finished = true; //上拉加载完毕
              }
            }

            if (this.isLoading) {
              //关闭下拉刷新
              this.isLoading = false; //关闭下拉刷新中
              this.contractData = res.data.data; //重新给数据赋值
              if (this.finished) {
                //如果上拉加载完毕为true则设为false。解决上拉加载完毕后再下拉刷新就不会执行上拉加载问题
                this.finished = false;
              }
            }
          } else {
            this.noData = false;
            this.loading = false;
            this.isLoading = false;
            this.finished = false;
            //当请求没有数据时 第一次请求
            if (res.data.data.length == 0) {
              this.noData = true;
            } else {
              this.contractData = res.data.data;
            }
            console.log(123);
          }
        } else {
          this.$toast(res.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // 刷新
    onRefresh() {
      this.isLoading = true;
      this.page = 1;
      if (this.contractData.length == 0) {
        this.isLoading = false;
      }
      this.getInvoicingContractListFun();
    },
    // 加载更多
    onLoad() {
      this.loading = true;
      this.timer = setTimeout(() => {
        this.page++;
        this.getInvoicingContractListFun();
      }, 1000);
    },
    changeSort(sort) {
      this.sort = sort;
    },
    changeType(type) {
      this.type = type;
    }
  }
};