var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(21);
      }
    }
  }, [_vm._v(" 住宅房屋租赁合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于住宅房屋业主直租场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(22);
      }
    }
  }, [_vm._v(" 购房代看合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于仅带看时付费,成交不付中介费的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(23);
      }
    }
  }, [_vm._v(" 车位租赁合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于私家车位租赁场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(24);
      }
    }
  }, [_vm._v(" 房屋买卖合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于房屋买卖场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(25);
      }
    }
  }, [_vm._v(" 商铺租赁合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于个体商户承租商铺的场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };