var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_vm.showCancel ? _c('div', {
    staticClass: "alert"
  }, [_vm._v("剩余" + _vm._s(_vm.showCancelDay) + "天自动关闭")]) : _vm._e(), _c('div', {
    staticClass: "sn"
  }, [_vm._v("编号：" + _vm._s(_vm.sn))]), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.vgoInfo();
      }
    }
  }, [_c('van-image', {
    attrs: {
      "round": "",
      "width": "1.2rem",
      "height": "1.2rem",
      "src": _vm.img1
    }
  })], 1), _c('div', [_c('div', {
    staticStyle: {
      "font-size": "0.4rem",
      "color": "#313131",
      "font-weight": "bold",
      "margin-bottom": "0.2rem"
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c('van-button', {
    attrs: {
      "plain": "",
      "hairline": "",
      "type": "primary",
      "size": "mini"
    }
  }, [_vm._v("借款人")])], 1), _c('div', [_c('van-image', {
    attrs: {
      "round": "",
      "width": "1.2rem",
      "height": "1.2rem",
      "src": _vm.imgB
    }
  })], 1), _c('div', [_c('div', {
    staticStyle: {
      "font-size": "0.4rem",
      "margin-bottom": "0.2rem"
    }
  }, [_vm._v(_vm._s(_vm.borrow))]), _c('van-button', {
    attrs: {
      "color": _vm.chu_color,
      "size": "mini"
    }
  }, [_vm._v("出借人")])], 1), _c('div', [_c('div', {
    staticStyle: {
      "font-size": "0.5rem",
      "color": "#e98923",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.status))]), _c('div', {
    staticStyle: {
      "font-size": "0.4rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v(_vm._s(_vm.statusTxt))])])]), _c('van-divider'), _vm.yanQiDetailShow ? _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("延期前")]), _c('van-form', {
    staticClass: "mylabel",
    staticStyle: {
      "font-size": "0.4rem"
    },
    attrs: {
      "input-align": "right"
    }
  }, [_c('van-field', {
    attrs: {
      "border": false,
      "name": "num",
      "label": "借款金额",
      "placeholder": "最低10元"
    },
    model: {
      value: _vm.num,
      callback: function ($$v) {
        _vm.num = $$v;
      },
      expression: "num"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "fee",
      "value": _vm.fee,
      "label": "借款利息"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "begin",
      "value": _vm.begin,
      "label": "开始日期"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "end",
      "value": _vm.end,
      "label": "还款日期"
    }
  })], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v("延期后")]), _c('van-form', {
    staticClass: "mylabel",
    staticStyle: {
      "font-size": "0.4rem"
    },
    attrs: {
      "input-align": "right"
    }
  }, [_c('van-field', {
    attrs: {
      "border": false,
      "name": "num",
      "label": "借款金额",
      "placeholder": "最低10元"
    },
    model: {
      value: _vm.num,
      callback: function ($$v) {
        _vm.num = $$v;
      },
      expression: "num"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "feeSum",
      "value": _vm.feeSum,
      "label": "借款利息"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "begin",
      "value": _vm.begin,
      "label": "开始日期"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "end2",
      "value": _vm.end2,
      "label": "还款日期"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('van-button', {
    staticStyle: {
      "width": "96%",
      "margin-left": "2%"
    },
    attrs: {
      "plain": "",
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.yanQiDetailShow = false;
      }
    }
  }, [_vm._v("取消 ")]), _c('van-button', {
    staticStyle: {
      "width": "96%",
      "margin-left": "2%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.vYanQi();
      }
    }
  }, [_vm._v("申请延期")])], 1)], 1) : _vm._e(), !_vm.yanQiDetailShow ? _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("借条信息")]), _c('van-form', {
    staticClass: "mylabel",
    staticStyle: {
      "font-size": "0.4rem"
    },
    attrs: {
      "input-align": "right"
    }
  }, [_c('van-field', {
    attrs: {
      "border": false,
      "name": "num",
      "label": "借款金额",
      "placeholder": "最低10元"
    },
    model: {
      value: _vm.num,
      callback: function ($$v) {
        _vm.num = $$v;
      },
      expression: "num"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "name": "pay_ways",
      "label": "还款方式"
    },
    model: {
      value: _vm.pay_ways,
      callback: function ($$v) {
        _vm.pay_ways = $$v;
      },
      expression: "pay_ways"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "begin",
      "value": _vm.begin,
      "label": "开始日期"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "end",
      "value": _vm.end,
      "label": "还款日期"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "fee",
      "value": _vm.fee,
      "label": "借款利息"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "name": "reason",
      "value": _vm.reason,
      "label": "借款用途"
    }
  }), _c('van-field', {
    attrs: {
      "border": false,
      "readonly": "",
      "label": "借款协议"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_vm.jitiao.rule_url == null ? _c('router-link', {
          attrs: {
            "to": "rulesample"
          }
        }, [_vm._v("查看>>")]) : _c('van-button', {
          attrs: {
            "size": "mini",
            "plain": "",
            "type": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.vlookRule();
            }
          }
        }, [_vm._v("查看")])];
      },
      proxy: true
    }], null, false, 4191127865)
  }), _c('van-field', {
    attrs: {
      "border": false,
      "type": "textarea",
      "readonly": "",
      "name": "note",
      "value": _vm.note,
      "label": "备注"
    }
  })], 1), _vm.jitiao.op_status == 3 || _vm.jitiao.op_status == 4 ? _c('div', {
    staticClass: "title"
  }, [_vm._v("延期信息")]) : _vm._e(), _c('van-form', {
    staticClass: "mylabel",
    staticStyle: {
      "font-size": "0.4rem"
    },
    attrs: {
      "input-align": "right"
    }
  }, [_vm.jitiao.op_status == 3 || _vm.jitiao.op_status == 4 ? _c('van-field', {
    staticStyle: {
      "padding-left": "0.4rem",
      "padding-right": "0.4rem"
    },
    attrs: {
      "input-align": "right",
      "border": false,
      "name": "num",
      "label": "延期时间"
    },
    model: {
      value: _vm.vDelayTime,
      callback: function ($$v) {
        _vm.vDelayTime = $$v;
      },
      expression: "vDelayTime"
    }
  }) : _vm._e(), _vm.jitiao.op_status == 3 || _vm.jitiao.op_status == 4 ? _c('van-field', {
    staticStyle: {
      "padding-left": "0.4rem",
      "padding-right": "0.4rem"
    },
    attrs: {
      "input-align": "right",
      "border": false,
      "name": "num",
      "label": "延期利息"
    },
    model: {
      value: _vm.vSumFee,
      callback: function ($$v) {
        _vm.vSumFee = $$v;
      },
      expression: "vSumFee"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v("交易信息")]), _c('van-form', {
    staticClass: "mylabel",
    staticStyle: {
      "font-size": "0.4rem"
    },
    attrs: {
      "input-align": "right"
    }
  }, [_c('van-field', {
    attrs: {
      "input-align": "right",
      "border": false,
      "name": "num",
      "label": "申请时间"
    },
    model: {
      value: _vm.create_time,
      callback: function ($$v) {
        _vm.create_time = $$v;
      },
      expression: "create_time"
    }
  }), _vm.deal_time != '' ? _c('van-field', {
    attrs: {
      "input-align": "right",
      "border": false,
      "name": "num",
      "label": "达成时间"
    },
    model: {
      value: _vm.deal_time,
      callback: function ($$v) {
        _vm.deal_time = $$v;
      },
      expression: "deal_time"
    }
  }) : _vm._e(), _vm.finish_time != '' ? _c('van-field', {
    attrs: {
      "input-align": "right",
      "border": false,
      "name": "num",
      "label": "完成时间"
    },
    model: {
      value: _vm.finish_time,
      callback: function ($$v) {
        _vm.finish_time = $$v;
      },
      expression: "finish_time"
    }
  }) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "font-size": "0.3rem",
      "margin-top": "1rem",
      "margin-bottom": "4rem"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "question"
    }
  }, [_vm._v("遇到了问题 >")])], 1), _vm.opStatus == 0 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.rejectTiao();
      }
    }
  }, [_vm._v("拒绝")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.remember();
      }
    }
  }, [_vm._v("同意")])], 1) : _vm.opStatus == 0 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.cancelOrder();
      }
    }
  }, [_vm._v("取消 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm._e(), _vm.opStatus == 1 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.rejectTiao();
      }
    }
  }, [_vm._v("拒绝")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.rememberNoPay();
      }
    }
  }, [_vm._v("同意")])], 1) : _vm.opStatus == 1 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.cancelOrder();
      }
    }
  }, [_vm._v("取消 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm.opStatus == 2 && _vm.who == 0 || _vm.opStatus == 52 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vDelayOrder();
      }
    }
  }, [_vm._v("我要延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vApplyFinishOrder();
      }
    }
  }, [_vm._v("我已还款 ")])], 1) : _vm.opStatus == 2 && _vm.who == 1 || _vm.opStatus == 52 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.yanQiShow = true;
      }
    }
  }, [_vm._v("申请延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.applyFinishPart();
      }
    }
  }, [_vm._v("部分消条")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.applyFinish();
      }
    }
  }, [_vm._v("一键消条")])], 1) : _vm.opStatus == 3 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vCancelDelay();
      }
    }
  }, [_vm._v("取消延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm.opStatus == 3 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vRejectDelay();
      }
    }
  }, [_vm._v("拒绝 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vAgreeDelay();
      }
    }
  }, [_vm._v("同意")])], 1) : _vm.opStatus == 4 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vRejectDelay();
      }
    }
  }, [_vm._v("拒绝 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vAgreeDelay();
      }
    }
  }, [_vm._v("同意")])], 1) : _vm.opStatus == 4 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vCancelDelay();
      }
    }
  }, [_vm._v("取消延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm.opStatus == 5 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vCancelApplyOrder();
      }
    }
  }, [_vm._v("取消申请 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm.opStatus == 5 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vAgreeFinish(0);
      }
    }
  }, [_vm._v("拒绝 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.vAgreeFinish(1);
      }
    }
  }, [_vm._v("同意")])], 1) : _vm.opStatus == 6 && _vm.who == 0 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.longOrder();
      }
    }
  }, [_vm._v("取消延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("提醒TA")])], 1) : _vm.opStatus == 6 && _vm.who == 1 ? _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "plain": "",
      "type": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.cancelOrder();
      }
    }
  }, [_vm._v("我要延期 ")]), _c('van-button', {
    staticStyle: {
      "flex-grow": "1",
      "margin": "0.1rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("我已还款")])], 1) : _vm._e()], 1) : _vm._e(), _c('van-overlay', {
    attrs: {
      "show": _vm.show
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "popur_top"
  }, [_c('van-image', {
    attrs: {
      "round": "",
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.imgShare
    }
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "font-weight": "bold",
      "padding": "0.4rem"
    }
  }, [_vm._v("我在信而签给您打了1张借条")]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background-color": "#eee"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "padding": "0.4rem",
      "color": "#1a1a1a"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }, [_c('div', [_vm._v("借款金额 " + _vm._s(_vm.num))]), _c('div', {
    staticStyle: {
      "margin-top": "0.4rem"
    }
  }, [_vm._v("还款时间 " + _vm._s(_vm.end))])]), _c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm._v("出借人")]), _c('div', {
    staticStyle: {
      "flex-grow": "1",
      "text-align": "right"
    }
  }, [_vm._v(_vm._s(_vm.borrow))])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "margin-top": "0.4rem"
    }
  }, [_c('div', [_vm._v("利息")]), _c('div', {
    staticStyle: {
      "flex-grow": "1",
      "text-align": "right"
    }
  }, [_vm._v(_vm._s(_vm.fee))])])])]), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('vue-qr', {
    attrs: {
      "logoSrc": _vm.imageUrl,
      "text": _vm.codeUrl,
      "size": 200
    }
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "font-size": "0.3rem",
      "margin": "0.5rem"
    }
  }, [_vm._v("长按下面二维码图片发送给朋友")])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHome,
      expression: "showHome"
    }],
    staticClass: "homebtn",
    on: {
      "click": function ($event) {
        return _vm.vGoHome();
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "width": "1rem",
      "height": "1rem",
      "color": "#fff",
      "name": "home-o"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "title": "延期交易详情"
    },
    model: {
      value: _vm.yanQiShow,
      callback: function ($$v) {
        _vm.yanQiShow = $$v;
      },
      expression: "yanQiShow"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.yanQiCont == 1,
      expression: "yanQiCont==1"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "延期时长",
      "value": _vm.yanQiTime,
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        _vm.yanQiCont = 2;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "延期利息",
      "value": _vm.yanQiFee,
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        _vm.yanQiCont = 3;
      }
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toYanQi();
      }
    }
  }, [_vm._v("确定")])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.yanQiCont == 2,
      expression: "yanQiCont==2"
    }],
    staticClass: "content"
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日",
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate
    },
    on: {
      "cancel": function ($event) {
        _vm.yanQiCont = 1;
      },
      "confirm": function ($event) {
        return _vm.yanQiTimeConfirm();
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function ($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.yanQiCont == 3,
      expression: "yanQiCont==3"
    }],
    staticClass: "content"
  }, [_c('van-picker', {
    ref: "feePicker",
    attrs: {
      "title": "年化利率",
      "show-toolbar": "",
      "columns": _vm.columns
    },
    on: {
      "confirm": _vm.onPickerConfirm,
      "cancel": function ($event) {
        _vm.yanQiCont = 1;
      }
    }
  })], 1)]), _c('van-action-sheet', {
    attrs: {
      "title": "交易密码"
    },
    model: {
      value: _vm.showSafePwd,
      callback: function ($$v) {
        _vm.showSafePwd = $$v;
      },
      expression: "showSafePwd"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('van-form', [_c('van-cell-group', [_c('van-field', {
    attrs: {
      "label": "",
      "placeholder": "请输入交易密码",
      "type": "password"
    },
    model: {
      value: _vm.safePwd,
      callback: function ($$v) {
        _vm.safePwd = $$v;
      },
      expression: "safePwd"
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.afterSafePwd();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)]), _c('van-action-sheet', {
    attrs: {
      "title": "交易密码"
    },
    model: {
      value: _vm.showSafePwdWithNum,
      callback: function ($$v) {
        _vm.showSafePwdWithNum = $$v;
      },
      expression: "showSafePwdWithNum"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('van-form', [_c('van-cell-group', {
    staticStyle: {
      "padding": "0.2rem"
    }
  }, [_c('van-field', {
    attrs: {
      "type": "digit",
      "placeholder": "请输入消条金额"
    },
    model: {
      value: _vm.finish_order_part_num,
      callback: function ($$v) {
        _vm.finish_order_part_num = $$v;
      },
      expression: "finish_order_part_num"
    }
  })], 1), _c('van-cell-group', {
    staticStyle: {
      "padding": "0.2rem"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "",
      "placeholder": "请输入交易密码",
      "type": "password"
    },
    model: {
      value: _vm.safePwd,
      callback: function ($$v) {
        _vm.safePwd = $$v;
      },
      expression: "safePwd"
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.afterSafePwdWithNum();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)]), _c('van-overlay', {
    attrs: {
      "show": _vm.gzshow
    },
    on: {
      "click": function ($event) {
        _vm.gzshow = false;
      }
    }
  }, [_c('div', {
    staticClass: "wrapper",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "block2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "center",
      "padding": "0.4rem"
    }
  }, [_vm._v("长按关注公众号，享受更多权益")]), _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-bottom": "1rem"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "5rem",
      "height": "5rem"
    },
    attrs: {
      "src": "https://tiao.dingshenghuijin.cc/img/gz.jpg"
    },
    on: {
      "click": function ($event) {
        _vm.gzshow = false;
      }
    }
  })]), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        _vm.gzshow = false;
      }
    }
  }, [_vm._v("关闭")])], 1)])]), _c('Dialog', {
    attrs: {
      "title": "请输入已还款金额"
    },
    on: {
      "confim": _vm.finish_part_num_click
    },
    model: {
      value: _vm.show_finish_order_part,
      callback: function ($$v) {
        _vm.show_finish_order_part = $$v;
      },
      expression: "show_finish_order_part"
    }
  }, [_c('van-cell-group', {
    staticStyle: {
      "padding": "0.5rem"
    }
  }, [_c('van-field', {
    attrs: {
      "type": "digit",
      "label": "金额",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.finish_order_part_num,
      callback: function ($$v) {
        _vm.finish_order_part_num = $$v;
      },
      expression: "finish_order_part_num"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };