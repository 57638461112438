var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('van-image', {
    staticClass: "img",
    attrs: {
      "round": "",
      "width": "1.5rem",
      "height": "1.5rem",
      "src": _vm.headimgurl
    }
  }), _c('div', {
    staticClass: "phone"
  }, [_c('div', [_vm._v(_vm._s(_vm.phone))]), !_vm.isMing ? _c('router-link', {
    attrs: {
      "to": "/shiming"
    }
  }, [_c('div', {
    staticClass: "renz"
  }, [_c('van-icon', {
    staticStyle: {
      "margin-right": "0.1125rem",
      "margin-left": "0.1rem"
    },
    attrs: {
      "name": "vip-card-o"
    }
  }), _vm._v("未实名认证 ")], 1)]) : _c('router-link', {
    attrs: {
      "to": "/info"
    }
  }, [_c('div', {
    staticClass: "renz"
  }, [_c('van-icon', {
    staticStyle: {
      "margin-right": "0.1125rem",
      "margin-left": "0.1rem"
    },
    attrs: {
      "name": "vip-card-o"
    }
  }), _vm._v("已实名认证 ")], 1)])], 1)], 1), _c('div', {
    staticClass: "yue"
  }, [_vm._m(0), _c('van-button', {
    staticStyle: {
      "border-radius": "0.2rem"
    },
    attrs: {
      "plain": ""
    },
    on: {
      "click": _vm.toCharge
    }
  }, [_vm._v("充值")]), _c('van-button', {
    staticStyle: {
      "margin-left": "0.5rem",
      "border-radius": "0.2rem"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.toGetMoney
    }
  }, [_vm._v("提现 ")])], 1), _c('van-cell', {
    staticStyle: {
      "margin-top": "0.4rem"
    },
    attrs: {
      "title": "待还",
      "value": _vm.sum1,
      "is-link": "",
      "to": "orderlist?type=1&status=1",
      "icon": "balance-o"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "待收",
      "value": _vm.sum2,
      "is-link": "",
      "to": "orderlist?type=2&status=1",
      "icon": "refund-o"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "合同列表",
      "is-link": "",
      "to": "contract",
      "icon": "description"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "仲裁中心",
      "is-link": "",
      "to": "zhongcai",
      "icon": "warning-o"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "强执中心",
      "is-link": "",
      "to": "qiangzi",
      "icon": "flag-o"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "设置",
      "is-link": "",
      "to": "setting",
      "icon": "setting-o"
    }
  }), _c('van-tabbar', {
    attrs: {
      "route": ""
    }
  }, [_c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/",
      "icon": "home-o"
    }
  }, [_vm._v("首页")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/orderlist",
      "icon": "after-sale"
    }
  }, [_vm._v("合同")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/msg",
      "icon": "chat-o"
    }
  }, [_vm._v("消息")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/my",
      "icon": "user-o"
    }
  }, [_vm._v("我的")])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "money"
  }, [_c('div', {
    staticClass: "txt"
  }, [_vm._v("账户余额（元）")]), _c('div', {
    staticClass: "num"
  }, [_vm._v("0.00")])]);
}];
export { render, staticRenderFns };