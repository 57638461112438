var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(11);
      }
    }
  }, [_vm._v(" 二手车买卖合同(简易版) ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于熟人之间买卖的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(12);
      }
    }
  }, [_vm._v(" 二手车买卖合同（通用版） ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于买方已熟知车况可快速签署的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(13);
      }
    }
  }, [_vm._v(" 二手车买卖合同(质保版) ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于买方担心事故车等风险、卖方愿意提供质保的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(14);
      }
    }
  }, [_vm._v(" 汽车分期买卖合同(分期付款) ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于分期付款场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };