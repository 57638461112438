import "core-js/modules/es.array.push.js";
import img1 from '@/assets/car/car_ricon.png';
export default {
  components: {},
  data() {
    return {
      img1,
      show: true,
      background: {
        // 背景图片地址
        backgroundImage: 'url(' + require('../../../assets/car/people.png') + ')',
        // 背景图片是否重复
        backgroundRepeat: 'no-repeat',
        // 背景图片大小
        backgroundSize: 'cover'
        // 背景颜色
        // backgroundColor: '#000',
        // 背景图片位置
        // backgroundPosition: 'center top'
      }
    };
  },

  created() {},
  methods: {
    toEsJy(e) {
      console.log('11');
      this.$router.push('/erji/car/people/peopleChange?type=' + e);
    }
  }
};