var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("对方姓名" + _vm._s(_vm.list.isbuyer == 1 ? '(卖方)' : '(买方)'))])]), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.toBuy();
      }
    }
  }, [_c('div', {
    staticStyle: {
      "color": "#999"
    }
  }, [_vm._v(_vm._s(!_vm.list.name ? '填写正确才能生效哦' : _vm.list.name))]), _c('van-icon', {
    attrs: {
      "name": "arrow",
      "color": "#999"
    }
  })], 1), _vm.list.type == 14 ? [_vm._m(0), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.color,
      callback: function ($$v) {
        _vm.$set(_vm.list, "color", $$v);
      },
      expression: "list.color"
    }
  })], 1), _vm._m(1), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.basePlate,
      callback: function ($$v) {
        _vm.$set(_vm.list, "basePlate", $$v);
      },
      expression: "list.basePlate"
    }
  })], 1), _vm._m(2), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.carType,
      callback: function ($$v) {
        _vm.$set(_vm.list, "carType", $$v);
      },
      expression: "list.carType"
    }
  })], 1), _vm._m(3), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.yearMoney,
      callback: function ($$v) {
        _vm.$set(_vm.list, "yearMoney", $$v);
      },
      expression: "list.yearMoney"
    }
  })], 1), _vm._m(4), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请选择",
      "clearable": ""
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    },
    model: {
      value: _vm.list.initiateTime,
      callback: function ($$v) {
        _vm.$set(_vm.list, "initiateTime", $$v);
      },
      expression: "list.initiateTime"
    }
  }), _c('van-calendar', {
    on: {
      "confirm": _vm.onConfirm
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  })], 1)] : _vm._e(), _vm.list.type == 12 || _vm.list.type == 13 ? [_vm._m(5), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.earnestMoney,
      callback: function ($$v) {
        _vm.$set(_vm.list, "earnestMoney", $$v);
      },
      expression: "list.earnestMoney"
    }
  })], 1)] : _vm._e(), _vm._m(6), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.price,
      callback: function ($$v) {
        _vm.$set(_vm.list, "price", $$v);
      },
      expression: "list.price"
    }
  })], 1), _vm.list.type == 14 ? [_vm._m(7), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.stages,
      callback: function ($$v) {
        _vm.$set(_vm.list, "stages", $$v);
      },
      expression: "list.stages"
    }
  })], 1), _vm._m(8), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请选择",
      "clearable": ""
    },
    on: {
      "click": function ($event) {
        _vm.isAlert = true;
      }
    },
    model: {
      value: _vm.list.amount,
      callback: function ($$v) {
        _vm.$set(_vm.list, "amount", $$v);
      },
      expression: "list.amount"
    }
  }), _c('van-popup', {
    style: {
      height: '40%'
    },
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.isAlert,
      callback: function ($$v) {
        _vm.isAlert = $$v;
      },
      expression: "isAlert"
    }
  }, [_c('van-picker', {
    attrs: {
      "title": "金额",
      "show-toolbar": "",
      "columns": _vm.columns
    },
    on: {
      "confirm": _vm.onAmount,
      "cancel": _vm.onCancel
    }
  })], 1)], 1), _vm._m(9), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.occupy,
      callback: function ($$v) {
        _vm.$set(_vm.list, "occupy", $$v);
      },
      expression: "list.occupy"
    }
  })], 1), _vm._m(10), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.downPayment,
      callback: function ($$v) {
        _vm.$set(_vm.list, "downPayment", $$v);
      },
      expression: "list.downPayment"
    }
  })], 1), _vm._m(11), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.payMonthly,
      callback: function ($$v) {
        _vm.$set(_vm.list, "payMonthly", $$v);
      },
      expression: "list.payMonthly"
    }
  })], 1)] : _vm._e(), _vm.list.type == 11 ? [_vm._m(12), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.desc,
      callback: function ($$v) {
        _vm.$set(_vm.list, "desc", $$v);
      },
      expression: "list.desc"
    }
  })], 1)] : _vm._e(), _vm._m(13), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.frameNumber,
      callback: function ($$v) {
        _vm.$set(_vm.list, "frameNumber", $$v);
      },
      expression: "list.frameNumber"
    }
  })], 1), _vm._m(14), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.generatorNumber,
      callback: function ($$v) {
        _vm.$set(_vm.list, "generatorNumber", $$v);
      },
      expression: "list.generatorNumber"
    }
  })], 1), _vm._m(15), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.brand,
      callback: function ($$v) {
        _vm.$set(_vm.list, "brand", $$v);
      },
      expression: "list.brand"
    }
  })], 1), _vm.list.type == 12 || _vm.list.type == 13 ? [_vm._m(16), _c('div', {
    staticClass: "boxs"
  }, [_c('van-field', {
    attrs: {
      "placeholder": "请填写",
      "clearable": ""
    },
    model: {
      value: _vm.list.mileage,
      callback: function ($$v) {
        _vm.$set(_vm.list, "mileage", $$v);
      },
      expression: "list.mileage"
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "upBtn"
  }, [_c('div', {
    staticClass: "left-btn"
  }, [_c('van-checkbox', {
    attrs: {
      "checked-color": "#688DFF"
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }), _c('div', {
    staticStyle: {
      "color": "#999",
      "margin-left": "5px"
    }
  }, [_vm._v("已阅读并同意")]), _c('div', {
    staticStyle: {
      "color": "#688DFF"
    },
    on: {
      "click": function ($event) {
        return _vm.toPdf();
      }
    }
  }, [_vm._v("《相关协议》")])], 1), !_vm.checked ? _c('div', {
    staticClass: "addBtn"
  }, [_vm._v(" 提交 ")]) : _c('div', {
    staticClass: "addBtn checkBtn",
    on: {
      "click": function ($event) {
        return _vm.up();
      }
    }
  }, [_vm._v(" 提交 ")])]), _c('van-action-sheet', {
    attrs: {
      "title": "交易详情"
    },
    model: {
      value: _vm.actionSheetShow,
      callback: function ($$v) {
        _vm.actionSheetShow = $$v;
      },
      expression: "actionSheetShow"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "交易账户",
      "value": _vm.phone
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付方式",
      "value": _vm.payways[_vm.payindex],
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        _vm.paywayShow = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付金额",
      "value": _vm.vShowMoney
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toPay();
      }
    }
  }, [_vm._v("确定支付")])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.paywayShow,
      expression: "paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "微信支付",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(0);
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "余额支付",
      "value": "余额不足",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(1);
      }
    }
  })], 1), _c('van-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.toPay
    }
  }, [_vm._v("确定支付 ")])], 1)])], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("外观颜色")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("底盘号")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("车型")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("每月付款日")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("分期起始日")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("定金(元)")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("总价格(元)")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("分期期数")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("第三者商业险金额")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("每月资金占用费")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("首付金额")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("分期月付金额")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("车辆概况")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("车架号")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("发动机号")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("车辆品牌")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("行驶里程")]), _c('div', {
    staticStyle: {
      "color": "#FF8215",
      "margin-top": "10px"
    }
  }, [_vm._v("*")])]);
}];
export { render, staticRenderFns };