var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticStyle: {
      "text-align": "center",
      "padding-top": "2rem"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "2rem",
      "height": "2rem",
      "src": _vm.imgA
    }
  }), _c('div', {
    staticStyle: {
      "margin-top": "0.5rem"
    }
  }, [_vm._v("信而签 V1.0")])], 1), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bot"
  }, [_c('div', [_vm._v("北京鼎胜汇金有限公司 版权所有")])]);
}];
export { render, staticRenderFns };