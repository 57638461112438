import { getQuestionInfo } from "@/api/rule";
export default {
  components: {},
  data() {
    return {
      qList: [],
      show: true,
      cont: '',
      id: 0
    };
  },
  created() {
    console.log(11);
    this.id = this.$route.query.id;
    console.log(this.id);
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        id: this.id
      };
      getQuestionInfo(params).then(resp => {
        if (resp.code == 200) {
          this.cont = resp.data;
        }
      });
    }
  }
};