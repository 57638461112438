var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "head"
  }, [_vm._m(0), _c('div', {
    staticClass: "btnBox"
  }, [_c('div', {
    class: _vm.index == 1 ? 'checked' : '',
    on: {
      "click": function ($event) {
        return _vm.one(1);
      }
    }
  }, [_vm._v("个人")]), _c('div', {
    class: _vm.index == 2 ? 'checked' : '',
    on: {
      "click": function ($event) {
        return _vm.one(2);
      }
    }
  }, [_vm._v("企业")])])]), _vm.index == 1 ? _c('div', {
    staticClass: "box"
  }, [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.name = $event.target.value;
      }
    }
  }), _vm._m(2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "text",
      "placeholder": "请输入",
      "maxlength": "11"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.phone = $event.target.value;
      }
    }
  }), _vm._m(3), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.idCard,
      expression: "idCard"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.idCard
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.idCard = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "addBtn",
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v("确认")])]) : _c('div', {
    staticClass: "box"
  }, [_vm._m(4), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.name = $event.target.value;
      }
    }
  }), _vm._m(5), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "number",
      "placeholder": "请输入",
      "maxlength": "11"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.phone = $event.target.value;
      }
    }
  }), _vm._m(6), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.idCard,
      expression: "idCard"
    }],
    staticClass: "inputs",
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.idCard
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.idCard = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "addBtn",
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v("确认")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("请填写对方信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("姓名"), _c('span', {
    staticStyle: {
      "color": "#FF8215"
    }
  }, [_vm._v("(必填)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("手机号"), _c('span', {
    staticStyle: {
      "color": "#FF8215"
    }
  }, [_vm._v("(必填)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("身份证号"), _c('span', [_vm._v("(选填)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("企业名称"), _c('span', {
    staticStyle: {
      "color": "#FF8215"
    }
  }, [_vm._v("(必填)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("手机号"), _c('span', {
    staticStyle: {
      "color": "#FF8215"
    }
  }, [_vm._v("(必填)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v("营业执照号"), _c('span', [_vm._v("(选填)")])]);
}];
export { render, staticRenderFns };