import "core-js/modules/es.array.push.js";
import img1 from '@/assets/msg/msg_img1.jpg';
import img2 from '@/assets/msg/msg_img2.jpg';
import img3 from '@/assets/msg/msg_img3.jpg';
import img4 from '@/assets/msg/msg_img4.jpg';
import { getToken } from '@/utils/auth';
import { getMsgList } from '@/api/user';
export default {
  name: 'Home',
  title: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      show: true,
      img1,
      img2,
      img3,
      img4,
      arrowTrue: true,
      page: 1,
      pageSize: 10,
      isLoading: false,
      loading: false,
      finished: false,
      noData: false,
      contractData: [],
      timer: null
    };
  },
  created() {
    if (!getToken()) {
      this.$router.push('login');
      return;
    }
    this.vGetMsgList();
  },
  methods: {
    tolist(type) {
      this.$router.push('msglist?type=' + type);
    },
    jump2() {
      this.$router.push('shiming');
    },
    jump3() {
      this.$router.push('zhongcai');
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    itemClick(id) {
      //this.$router.push('msginfo?id='+id);
    },
    timeCompute(pay_time) {
      // pay_time = 1670836556
      let num = this.$dayjs(this.$dayjs(pay_time * 1000).format("YYYY-MM-DD")).diff(this.$dayjs(), 'day');
      if (num > 0) {
        return '<span>还剩<span style="padding-left:0.2rem;padding-right:0.2rem;">' + num + '</span>天</span>';
      } else if (num == 0) {
        return '<van-icon name="balance-list-o">今日还款</van-icon>';
      } else {
        return '<span>逾期<span style="color:red;padding-left:0.2rem;padding-right:0.2rem;">' + -num + '</span>天</span>';
      }
    },
    // 数据列表方法
    vGetMsgList() {
      let params = {
        type: this.type,
        page: this.page,
        pageSize: this.pageSize
      };
      getMsgList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          console.log(this.contractData);
          if (this.contractData.length > 0) {
            this.noData = false;
            //当请求前有数据时 第n次请求
            if (this.loading) {
              // 上拉加载
              this.contractData = this.contractData.concat(res.data.data); //上拉加载新数据添加到数组中
              this.$nextTick(() => {
                //在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false; //关闭上拉加载中
              });

              if (res.data.data.length == 0) {
                //没有更多数据
                this.finished = true; //上拉加载完毕
              }
            }

            if (this.isLoading) {
              //关闭下拉刷新
              this.isLoading = false; //关闭下拉刷新中
              this.contractData = res.data.data; //重新给数据赋值
              if (this.finished) {
                //如果上拉加载完毕为true则设为false。解决上拉加载完毕后再下拉刷新就不会执行上拉加载问题
                this.finished = false;
              }
            }
          } else {
            this.noData = false;
            this.loading = false;
            this.isLoading = false;
            this.finished = false;
            //当请求没有数据时 第一次请求
            if (res.data.data.length == 0) {
              this.noData = true;
            } else {
              this.contractData = res.data.data;
            }
            console.log(123);
          }
        } else {
          this.$toast(res.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // 刷新
    onRefresh() {
      this.isLoading = true;
      this.page = 1;
      if (this.contractData.length == 0) {
        this.isLoading = false;
      }
      this.vGetMsgList();
    },
    // 加载更多
    onLoad() {
      this.loading = true;
      this.timer = setTimeout(() => {
        this.page++;
        this.getInvoicingContractListFun();
      }, 1000);
    }
  }
};