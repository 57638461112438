import pdf from 'vue-pdf';
export default {
  components: {
    pdf
  },
  data() {
    return {
      pdfUrl: "https://tiaoui.dingshenghuijin.cc/samplemod/car.pdf",
      pageTotal: null
    };
  },
  created() {
    this.getTotal();
  },
  methods: {
    // 获取pdf总页数
    getTotal() {
      // 多页pdf的src中不能直接使用后端获取的pdf地址 否则会按页数请求多次数据
      // 需要使用下述方法的返回值作为url
      this.pdfUrl = pdf.createLoadingTask(this.pdfUrl);
      // 获取页码
      this.pdfUrl.promise.then(pdf => this.pageTotal = pdf.numPages).catch(error => {});
    }
  }
};