var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('van-cell-group', _vm._l(_vm.qList, function (item) {
    return _c('van-cell', {
      key: item.id,
      attrs: {
        "is-link": "",
        "title": item.title,
        "to": 'questioninfo?id=' + item.id
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };