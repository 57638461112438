var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_vm.noData ? _c('div', {
    staticClass: "p30 text-center fontSize30"
  }, [_c('van-empty', {
    attrs: {
      "image": "search",
      "description": "暂无数据"
    }
  })], 1) : _vm._e(), _c('van-pull-refresh', {
    staticClass: "content",
    attrs: {
      "success-text": "刷新成功"
    },
    on: {
      "refresh": _vm.onRefresh
    },
    model: {
      value: _vm.isLoading,
      callback: function ($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  }, [_vm.contractData.length > 0 ? _c('van-list', {
    attrs: {
      "finished": _vm.finished,
      "immediate-check": false,
      "finished-text": "我也是有底线的",
      "offset": 10
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.contractData, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "van-hairline--left item",
      on: {
        "click": function ($event) {
          return _vm.itemClick(item.id);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('van-icon', {
      attrs: {
        "name": "volume-o",
        "color": "red"
      }
    }), _c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))])], 1), _c('van-divider'), _c('div', {
      staticStyle: {
        "display": "flex",
        "margin-top": "0.3rem",
        "padding-bottom": "0.8rem"
      }
    }, [_c('div', {
      staticClass: "cont",
      staticStyle: {
        "flex-grow": "1"
      }
    }, [_vm._v(" " + _vm._s(item.brief) + " ")])])], 1);
  }), 0) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };