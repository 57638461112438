import { getInfo } from '@/api/rule';
export default {
  data() {
    return {
      cont: ''
    };
  },
  created() {
    var _this$$route$query$ty;
    let type = (_this$$route$query$ty = this.$route.query.type) !== null && _this$$route$query$ty !== void 0 ? _this$$route$query$ty : 1;
    if (type == 1) {
      document.title = '用户协议';
    }
    let params = {
      type
    };
    getInfo(params).then(resp => {
      console.log(resp);
      this.cont = resp.data;
    });
  }
};