var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.msg.title))]), _c('div', {
    staticStyle: {
      "text-align": "right",
      "font-size": "0.3rem"
    }
  }, [_vm._v("发布日期：" + _vm._s(_vm.$dayjs(_vm.msg.create_time * 1000).format("YYYY-MM-DD")))]), _c('van-divider'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.cont)
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };