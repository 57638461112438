import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
import { submitJietiao } from '@/api/user';
import { getPayConfig } from '@/api/order';
export default {
  data() {
    return {
      vShowMoney: '8.8元',
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2028, 10, 1),
      currentDate: new Date(),
      whichTime: 0,
      uInfo: {},
      values: {},
      sn: '',
      phone: '',
      payways: ['微信支付', '余额支付'],
      payindex: 0,
      paywayShow: false,
      actionSheetShow: false,
      checkbox: false,
      active: 0,
      name: '',
      name2: '',
      num: "",
      note: "",
      begin: '',
      end: '',
      showCalendar: false,
      showCalendar2: false,
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      fee: '0%,0元',
      fee_money: '0',
      fee_year: '0',
      reason: '临时周转',
      reasonIndex: 0,
      ways: '诉讼',
      waysIndex: 0,
      columns: [],
      columns2: ['临时周转', '消费', '交房租', '还信用卡', '其它'],
      columns3: ['诉讼'],
      wxReady: false
    };
  },
  created() {
    if (!getToken()) {
      console.log('no token');
      this.$router.push('login');
      return;
    }
    let that = this;
    let uInfo = JSON.parse(localStorage.getItem('xinguserInfo'));
    console.log(uInfo.isMing);
    if (uInfo.isMing != 1) {
      console.log('show diglog');
      _Dialog.confirm({
        title: '提示',
        message: '未完成实名认证，请先认证'
      }).then(() => {
        // on confirm
        that.$router.push('shiming');
      }).catch(() => {
        that.$router.go(-1);
      });
      return;
    }
    this.uInfo = uInfo;
    this.phone = uInfo.phone;
    this.begin = this.$dayjs().format('YYYY-MM-DD');
    this.end = this.$dayjs().add(6, 'day').format('YYYY-MM-DD');
    this.name = uInfo.name;
    this.columns = [];
    for (var i = 0; i < 36; i++) {
      this.columns.push(i + "%");
    }
    this.$wx.ready(function () {
      console.log('wx read');
      that.wxReady = true;
    });
  },
  watch: {
    // 'currentDate':{
    // 	handler(newV,oldV){
    // 		if (this.whichTime==1) {
    // 			console.log(this.currentDate)
    // 		}
    // 	}
    // }
  },
  methods: {
    timeConfirm(value) {
      this.showCalendar = false;
      this.showCalendar2 = false;
      if (this.whichTime == 1) {
        this.end = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getDate();
      } else {
        this.begin = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getDate();
      }
    },
    timeCancel() {
      this.showCalendar2 = false;
    },
    showTimePicker(which) {
      this.whichTime = which;
      this.showCalendar2 = true;
      if (which == 1) {
        let year = this.begin.split('-')[0];
        let month = this.begin.split('-')[1] - 1;
        let day = this.begin.split('-')[2];
        let year2 = this.end.split('-')[0];
        let month2 = this.end.split('-')[1] - 1;
        let day2 = this.end.split('-')[2];
        this.minDate = new Date(year, month, day);
        this.currentDate = new Date(year2, month2, day2);
      } else {
        let year = this.begin.split('-')[0];
        let month = this.begin.split('-')[1] - 1;
        let day = this.begin.split('-')[2];
        this.currentDate = new Date(year, month, day);
      }
    },
    changePayWays(index) {
      if (index == 1) {
        _Toast('余额不足，请重新选择');
        return;
      }
      this.paywayShow = false;
    },
    toPay() {
      if (!this.wxReady) {
        _Toast('微信登录中，请稍候，或者刷新重试');
        return;
      }
      let that = this;
      this.actionSheetShow = false;
      this.values.status = -1;
      submitJietiao(this.values).then(resp => {
        that.sn = resp.data.sn;
        let param = {
          'sn': that.sn,
          'openid': that.uInfo.openid
        };
        getPayConfig(param).then(resp => {
          if (resp.code == 200) {
            let data = resp.data;
            that.$wx.chooseWXPay({
              timestamp: data.timeStamp,
              // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
              nonceStr: data.nonceStr,
              // 支付签名随机串，不长于 32 位
              package: data.package,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: data.signType,
              // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: data.paySign,
              // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                console.log('pay success');
                // let upparam = {
                // 	sn: that.sn,
                // 	avatar: that.uInfo.headimgurl
                // }
                // uploadAvatar(upparam);

                // that.status = 1;
                // that.pay_time = 1;
                that.$router.push('orderinfo?isShare=0&sn=' + that.sn);
              }
            });
          }
        });
      });
    },
    onPickerConfirm(value, index) {
      console.log(value);
      console.log(index);
      this.fee_year = index;
      let days = this.$dayjs(this.end).diff(this.begin, 'day');
      days++;
      // if(days<1){
      // 	Toast('还款日期小于借款日期')
      // 	return
      // }
      console.log(days);
      let tmp = this.num * index / 360 * days / 100;
      console.log(tmp);
      this.fee_money = tmp.toFixed(2);
      this.fee = value + ',' + this.fee_money + '元';
      this.showPicker = false;
    },
    onPickerConfirm2(value, index) {
      this.reason = value;
      this.reasonIndex = index;
      this.showPicker2 = false;
    },
    onPickerConfirm3(value, index) {
      this.ways = value;
      this.waysIndex = index;
      this.showPicker3 = false;
    },
    onConfirm(date) {
      this.begin = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    onConfirm2(date) {
      this.end = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      let days = this.$dayjs(this.end).diff(this.begin, 'day');
      if (days < 0) {
        _Toast('还款日期小于借款日期');
        return;
      }
      this.showCalendar2 = false;
    },
    onClick(name, title) {
      // Toast(title);
    },
    onSubmit(values) {
      let that = this;
      let uInfo = JSON.parse(localStorage.getItem('xinguserInfo'));
      if (uInfo.isMing != 1) {
        _Dialog.confirm({
          title: '提示',
          message: '未完成实名认证，请先认证'
        }).then(() => {
          // on confirm
          that.$router.push('shiming');
        }).catch(() => {
          // that.$router.go(-1)
        });
        return;
      }
      let days = this.$dayjs(this.end).diff(this.begin, 'day');
      if (days < 0) {
        _Toast('还款日期小于借款日期');
        return;
      }
      if (values.name == values.name2) {
        _Toast('自己不能给自己打借条');
        return;
      }
      values.name = values.name.trim();
      values.name2 = values.name2.trim();

      // console.log('submit', values);
      // return;
      values.fee = this.fee_money * 100;
      values.type = this.active;
      values.resolution_way = this.waysIndex;
      values.reason = this.reasonIndex;
      values.fee_year = this.fee_year;

      // let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"))
      values.avatar = uInfo.headimgurl;
      console.log('submit', values);
      if (values.type == 1) {
        submitJietiao(values).then(resp => {
          that.$router.push('orderinfo?isShare=0&sn=' + resp.data.sn);
        });
      } else {
        _Dialog.confirm({
          title: '提示',
          message: '请您确认已线下完成交易，对方确认后借条即刻生效，您确认发起该借条吗？'
        }).then(() => {
          //变价
          if (that.num < 10000) {
            that.vShowMoney = '8.8元';
          } else if (that.num < 20000) {
            that.vShowMoney = '18.8元';
          } else if (that.num < 50000) {
            that.vShowMoney = '28.8元';
          } else if (that.num < 100000) {
            that.vShowMoney = '48.8元';
          } else {
            that.vShowMoney = '68.8元';
          }

          // on confirm
          that.paywayShow = false;
          that.actionSheetShow = true;
          that.values = values;
        }).catch(() => {
          // on cancel
        });
      }
    }
  }
};