var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(71);
      }
    }
  }, [_vm._v(" 代发合作合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于无货源电商的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(72);
      }
    }
  }, [_vm._v(" 代购担保合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于双方不熟悉,代购人提供国内担保的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(73);
      }
    }
  }, [_vm._v(" 代购合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于代购人有中国身份证的场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };