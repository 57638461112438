var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page",
    domProps: {
      "innerHTML": _vm._s(_vm.cont)
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };