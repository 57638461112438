var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-image', {
    attrs: {
      "src": _vm.img1
    }
  }), _c('van-form', {
    attrs: {
      "validate-first": ""
    },
    on: {
      "failed": _vm.onFailed,
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "type": "password",
      "name": "password",
      "label": "密码",
      "placeholder": "密码",
      "rules": [{
        required: true,
        message: '请填写密码'
      }]
    },
    model: {
      value: _vm.value1,
      callback: function ($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('van-field', {
    attrs: {
      "type": "password",
      "name": "password2",
      "label": "确认密码",
      "placeholder": "再次输入密码",
      "rules": [{
        required: true,
        message: '请填写确认密码'
      }]
    },
    model: {
      value: _vm.value2,
      callback: function ($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("下一步")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };