import "core-js/modules/es.array.push.js";
import Bus from '@/utils/bus.js';
export default {
  data() {
    return {
      type: ''
    };
  },
  beforeDestroy() {
    Bus.$off("Data");
  },
  created() {
    this.type = this.$route.query.type;
  },
  methods: {
    toBuy(e) {
      this.$router.push('/erji/car/live/liveBuy?isbuyer=' + e + '&type=' + this.type);
    }
  }
};