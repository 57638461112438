var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('van-cell', {
    attrs: {
      "title": "手机号码",
      "value": _vm.phone
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-top": "0.2125rem"
    },
    attrs: {
      "title": "实名认证",
      "value": _vm.hasName,
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.vRenZhen();
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "银行卡",
      "is-link": "",
      "value": _vm.hasBank
    },
    on: {
      "click": function ($event) {
        return _vm.vRenZhen();
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "邮箱",
      "is-link": "",
      "value": _vm.hasMail
    },
    on: {
      "click": function ($event) {
        return _vm.vRenZhen();
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "修改登录密码",
      "is-link": "",
      "to": "changepwd"
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-bottom": "0.9375rem"
    },
    attrs: {
      "title": "修改交易密码",
      "is-link": "",
      "to": "changesafepwd"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };