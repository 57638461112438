import imgA from '@/assets/logo.png';
export default {
  components: {},
  data() {
    return {
      imgA,
      show: true
    };
  },
  created() {},
  methods: {}
};