var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(41);
      }
    }
  }, [_vm._v(" 买卖合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于买卖货物的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(42);
      }
    }
  }, [_vm._v(" 五金电器家电购销合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于五金家电采销的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(43);
      }
    }
  }, [_vm._v(" 供货合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于实际供货量结算的场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(44);
      }
    }
  }, [_vm._v(" 办公用品及耗材采购合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于办公用品采购的场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };