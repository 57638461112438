import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import Bus from '@/utils/bus.js';
export default {
  data() {
    return {
      name: '',
      phone: '',
      index: 1,
      idCard: ''
    };
  },
  created() {},
  methods: {
    one(e) {
      this.index = e;
    },
    back() {
      if (!this.name) {
        _Notify({
          type: 'danger',
          message: this.index == 1 ? '请填写姓名' : '请填写公司名称'
        });
        return;
      }
      if (!this.phone) {
        _Notify({
          type: 'danger',
          message: '请填写电话'
        });
        return;
      }
      var data = {
        name: this.name,
        phone: this.phone,
        idCard: this.idCard
      };
      this.$router.go(-1);
      setTimeout(() => {
        Bus.$emit('Data', data);
      }, 100);
    }
  }
};