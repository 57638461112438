var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.toBuy(1);
      }
    }
  }, [_c('div', [_vm._v("我是买方")]), _c('van-icon', {
    attrs: {
      "name": "arrow",
      "color": "#688DFF"
    }
  })], 1), _c('div', {
    staticClass: "box",
    on: {
      "click": function ($event) {
        return _vm.toBuy(0);
      }
    }
  }, [_c('div', [_vm._v("我是卖方")]), _c('van-icon', {
    attrs: {
      "name": "arrow",
      "color": "#688DFF"
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "left-box"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v("请选择您的身份")])]);
}];
export { render, staticRenderFns };