import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
import Bus from '@/utils/bus.js';
import { submitcar, getPayConfigOther } from '@/api/user';
import { getPayConfig } from '@/api/order';
export default {
  data() {
    return {
      columns: ['10万', '20万', '50万', '100万', '200万', '300万'],
      checked: false,
      actionSheetShow: false,
      paywayShow: false,
      phone: '',
      payways: ['微信支付', '余额支付'],
      vShowMoney: '980元',
      payindex: 0,
      values: {},
      uInfo: {},
      sn: '',
      list: {
        name: '',
        idCard: '',
        phone: '',
        area: '',
        address: '',
        // year:'',
        desc: '',
        price: '',
        type: 41,
        isbuyer: 1,
        earnestMoney: '',
        mileage: '',
        item: '',
        // carType:'',
        productName: '',
        year: ''
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    //to是当前页面,from是从哪里来,next是放行
    if (to.query.type) {
      if (to.query.type == 51) {
        document.title = '赠与合同'; //获取从上个页面传过来的name再进行修改
      } else if (to.query.type == 52) {
        document.title = '健身会员合同';
      } else if (to.query.type == 53) {
        document.title = '装饰装修合同';
      } else if (to.query.type == 54) {
        document.title = '家政服务合同';
      }
    }
    next(); //切记操作完一定要记得放行,否则无法正常跳转页面
  },

  created(e) {
    this.list.isbuyer = this.$route.query.isbuyer;
    this.list.type = this.$route.query.type;
    if (!getToken()) {
      console.log('no token');
      this.$router.push('/login');
      return;
    }
    let that = this;
    let uInfo = JSON.parse(localStorage.getItem('xinguserInfo'));
    console.log(uInfo.isMing);
    if (uInfo.isMing != 1) {
      console.log('show diglog');
      _Dialog.confirm({
        title: '提示',
        message: '未完成实名认证，请先认证'
      }).then(() => {
        // on confirm
        that.$router.push('/shiming');
      }).catch(() => {
        that.$router.go(-1);
      });
      return;
    }
    this.uInfo = uInfo;
    this.$wx.ready(function () {
      console.log('wx read');
      that.wxReady = true;
    });
  },
  mounted() {
    this.activated();
  },
  beforeDestroy() {
    // Bus.$off("Data",this.activated());
  },
  methods: {
    onCancel() {
      this.isAlert = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    activated() {
      Bus.$on('Data', data => {
        //赋值给当前页面的值
        console.log(data, '222');
        this.list.name = data.name;
        this.list.phone = data.phone;
        this.list.idCard = data.idCard;
        console.log(this.list);
      });
      console.log(this.list);
    },
    up() {
      if (!this.list.name) {
        _Notify({
          type: 'danger',
          message: '请填写对方姓名'
        });
        return;
      }
      if (!this.list.price) {
        _Notify({
          type: 'danger',
          message: '请填写总价格'
        });
        return;
      }
      if (this.list.type == 52 || this.list.type == 53) {
        if (!this.list.productName) {
          _Notify({
            type: 'danger',
            message: '请填写产品介绍'
          });
          return;
        }
        if (!this.list.year) {
          _Notify({
            type: 'danger',
            message: '请填写年限'
          });
          return;
        }
      }
      if (this.list.type == 53 || this.list.type == 53) {
        if (!this.list.earnestMoney) {
          _Notify({
            type: 'danger',
            message: '请填写定金'
          });
          return;
        }
        if (!this.list.address) {
          _Notify({
            type: 'danger',
            message: '请填写地址'
          });
          return;
        }
        if (!this.list.area) {
          _Notify({
            type: 'danger',
            message: '请填写面积'
          });
          return;
        }
      }
      // if(!this.list.year){ Notify({ type: 'danger', message:'请填写年限'}); return}
      // if(!this.list.address){ Notify({ type: 'danger', message:'请填写地址'}); return}
      // if(!this.list.area){ Notify({ type: 'danger', message:'请填写面积'}); return}
      if (this.list.type == 51) {
        if (!this.list.item) {
          _Notify({
            type: 'danger',
            message: '请填写物品名称'
          });
          return;
        }
        // if(!this.list.carType){ Notify({ type: 'danger', message:'请填写车型'}); return}
      }

      submitcar(this.list).then(res => {
        console.log(res);
        if (res.code == 200) {
          _Notify({
            type: 'success',
            message: '提交成功'
          });
          this.paywayShow = false;
          this.actionSheetShow = true;
          this.sn = res.data.sn;
        } else {
          _Notify({
            type: 'danger',
            message: '提交失败'
          });
        }
      });
    },
    changePayWays(index) {
      if (index == 1) {
        _Toast('余额不足，请重新选择');
        return;
      }
      this.paywayShow = false;
    },
    toPay() {
      if (!this.wxReady) {
        _Toast('微信登录中，请稍候，或者刷新重试');
        return;
      }
      let that = this;
      this.actionSheetShow = false;
      // this.values.status = -1
      let param = {
        'sn': that.sn,
        'openid': that.uInfo.openid,
        'type': 5
      };
      getPayConfigOther(param).then(resp => {
        if (resp.code == 200) {
          let data = resp.data;
          that.$wx.chooseWXPay({
            timestamp: data.timeStamp,
            // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: data.nonceStr,
            // 支付签名随机串，不长于 32 位
            package: data.package,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.signType,
            // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: data.paySign,
            // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log('pay success');
              console.log(res);
              // let upparam = {
              // 	sn: that.sn,
              // 	avatar: that.uInfo.headimgurl
              // }
              // uploadAvatar(upparam);

              // that.status = 1;
              // that.pay_time = 1;
              that.$router.push('orderinfo?isShare=0&sn=' + that.sn);
            }
          });
        }
      });
    },
    changes() {
      this.checked = !this.checked;
    },
    toPdf() {
      this.$router.push('/look');
    },
    toBuy() {
      this.$router.push('/erji/car/room/changeRoom');
    }
  }
};