var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-form', {
    attrs: {
      "validate-first": ""
    },
    on: {
      "failed": _vm.onFailed,
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "type": "password",
      "name": "pwd",
      "label": "登录密码",
      "placeholder": "登录密码",
      "rules": [{
        required: true,
        message: '请填写密码'
      }]
    },
    model: {
      value: _vm.value4,
      callback: function ($$v) {
        _vm.value4 = $$v;
      },
      expression: "value4"
    }
  }), _c('van-field', {
    attrs: {
      "type": "password",
      "name": "pwd2",
      "label": "密码确认",
      "placeholder": "密码确认",
      "rules": [{
        required: true,
        message: '请填写密码'
      }]
    },
    model: {
      value: _vm.value6,
      callback: function ($$v) {
        _vm.value6 = $$v;
      },
      expression: "value6"
    }
  }), _c('van-field', {
    attrs: {
      "name": "phone",
      "label": "手机号",
      "placeholder": "手机号",
      "rules": [{
        validator: _vm.validator,
        message: '请输入正确手机号'
      }]
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function () {
        return [_vm.timerTf ? _c('van-button', {
          attrs: {
            "native-type": "button",
            "size": "small",
            "type": "primary"
          },
          on: {
            "click": _vm.receiveCode
          }
        }, [_vm._v(" 发送验证码")]) : _c('van-button', {
          attrs: {
            "native-type": "button",
            "size": "small",
            "type": "primary"
          }
        }, [_vm._v("重新获取(" + _vm._s(_vm.countDownNum) + ")秒 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value3,
      callback: function ($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  }), _c('van-field', {
    attrs: {
      "name": "code",
      "label": "验证码",
      "placeholder": "验证码",
      "rules": [{
        required: true,
        message: '请填写验证码'
      }]
    },
    model: {
      value: _vm.value5,
      callback: function ($$v) {
        _vm.value5 = $$v;
      },
      expression: "value5"
    }
  }), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };