import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
import img1 from '@/assets/avatar.jpg';
import img2 from '@/assets/renz/share_bg.jpg';
import imageUrl from '@/assets/logo.png';
import { getToken } from '@/utils/auth';
import vueQr from 'vue-qr';
import { getInfo, getWxUserInfo, getPayConfig, getWxConfig, isHasWxToken, uploadAvatar, userCancelOrder, rejectOrder, agreeOrder, finishOrder, finishOrderPart, applyFinishOrder, applyFinishOrderPart, rejectFinishOrder, applyDelayOrder, cancelDelay, rejectDelay, agreeDelay, applyFinishOrderCancel, getLookUrl, isGz } from '@/api/order';
export default {
  components: {
    vueQr,
    Dialog: _Dialog.Component
  },
  data() {
    return {
      showSafePwdWithNum: false,
      show_finish_order_part: false,
      finish_order_part_num: '',
      gzshow: false,
      vDelayTime: '',
      vSumFee: '0.00',
      feeSum: 0,
      afterSafePwdAction: 0,
      end2: '',
      yanQiDetailShow: false,
      finish_time: '',
      deal_time: '',
      safePwd: '',
      yanQiFeeIndex: 0,
      showSafePwd: false,
      columns: [],
      yanQiCont: 1,
      yanQiDays: 30,
      yanQiShow: false,
      yanQiTime: '0',
      yanQiFee: '0',
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
      showHome: true,
      opStatus: 99,
      who: 0,
      codeUrl: '',
      pay_time: 0,
      jitiao: {},
      status: '待确认',
      statusArr: ['待确认', '待收款', '已逾期', '已收款', '已失效'],
      statusTxt: '申请待确认',
      statusTxtArr: ['申请待确认', '借条待收款', '用户已逾期', '已线下还款', '用户已取消'],
      uInfo: {},
      chu_color: "#ccc",
      showCancel: false,
      showCancelDay: 3,
      imageUrl,
      img1,
      img2,
      imgB: img1,
      sn: '',
      name: '',
      borrow: '',
      show: false,
      num: '300元',
      create_time: '2020/12/25 21:50:12',
      pay_ways: '到期还本付息',
      begin: '2020/12/12',
      end: '2020/12/12',
      fee: '0.00元',
      reason: '临时周转',
      resolution_way: '诉讼',
      resolution_wayArr: ['诉讼'],
      reasonArr: ['临时周转'],
      note: '备注信息',
      code: '',
      imgShare: '',
      isShare: 0,
      wxReady: false
    };
  },
  mounted() {
    let that = this;
    setInterval(this.showHomeBtn, 1500);
    console.log(this.opStatus);
    console.log(this.who);

    // this.gzshow = this.$store.getters.gz?false:true;
    // console.error(this.$store.getters.gz)
  },

  created() {
    console.log('create');
    // this.getCode();return;
    this.sn = this.$route.query.sn;
    // this.isShare = this.$route.query.isShare ?? 0
    this.codeUrl = this.$domain + this.$route.path + '?sn=' + this.sn;
    // if (!this.isShare) {
    // 	console.log('return')
    // 	return;
    // }

    // this.getData();
    let tmp = localStorage.getItem('xinguserInfo');
    if (tmp) {
      this.uInfo = JSON.parse(tmp);
      this.getData();
      this.imgShare = this.uInfo.headimgurl;
      // this.getData();
    } else {
      console.log('not code');
      this.getCode();
    }

    // this.shareFriend()
    // this.uInfo = {
    // 	name:'李四'
    // }
    // localStorage.setItem('xinguserInfo',JSON.stringify(this.uInfo))
  },

  methods: {
    vgoInfo() {
      if (!this.jitiao.user_id) {
        return;
      } else {
        this.$router.push('/info?id=' + this.jitiao.user_id);
      }
    },
    vGoHome() {
      this.$router.push('/');
    },
    //判断是否关注
    vIsGz() {
      if (this.uInfo.openid) {
        isGz({
          openid: this.uInfo.openid
        }).then(resp => {
          if (resp.data == 0) {
            this.gzshow = true;
          }
          // this.gzshow = true
        });
      }
    },

    vlookRule() {
      getLookUrl({
        sn: this.sn
      }).then(resp => {
        window.location.href = resp.data;
      });
    },
    vCancelDelay() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '取消申请后，对方无法同意延期请求，确定继续吗？'
      }).then(() => {
        cancelDelay({
          sn: that.sn,
          who: that.who
        }).then(() => {
          that.$router.go(0);
        });
      }).catch(() => {
        // on cancel
      });
    },
    vAgreeDelay() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '确定继续吗？'
      }).then(() => {
        if (that.who == 1) {
          agreeDelay({
            sn: that.sn,
            who: that.who
          }).then(() => {
            that.$router.go(0);
          });
        } else {
          this.toPayForYanQi();
        }
      }).catch(() => {
        // on cancel
      });
    },
    vRejectDelay() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '取消申请后，对方无法同意延期请求，确定继续吗？'
      }).then(() => {
        rejectDelay({
          sn: that.sn,
          who: this.who
        }).then(() => {
          that.$router.go(0);
        });
      }).catch(() => {
        // on cancel
      });
    },
    vYanQi() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      this.afterSafePwdAction = 1;
      this.showSafePwd = true;

      // this.yanQiDetailShow = false;
    },

    vAgreeFinish(index) {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      if (index == 1) {
        _Dialog.confirm({
          title: '同意消条',
          message: '借条将变更为已还款，确定已完成线下交易，确定消条吗？'
        }).then(() => {
          that.safePwd = '';
          that.showSafePwd = true;
        }).catch(() => {
          // on cancel
        });
      } else {
        _Dialog.confirm({
          title: '拒绝消条',
          message: '未完成线下交易，拒绝消条吗？'
        }).then(() => {
          rejectFinishOrder({
            sn: this.sn
          }).then(() => {
            that.updateStatus(2, 2);
            _Toast('操作成功');
          });
        }).catch(() => {
          // on cancel
        });
      }
    },
    afterSafePwd() {
      if (!this.safePwd) {
        _Toast('交易密码不能为空');
        return;
      }
      let that = this;
      that.showSafePwd = false;
      if (this.afterSafePwdAction == 1) {
        let paramDelay = {
          sn: this.sn,
          delay_time: this.end2,
          feeSum: this.feeSum,
          pwd: this.safePwd,
          who: this.who
        };
        applyDelayOrder(paramDelay).then(() => {
          if (this.who == 0) {
            this.toPayForYanQi();
          } else {
            that.$router.go(0);
          }
        });
        return;
      } else {}
      finishOrder({
        sn: this.sn,
        pwd: this.safePwd
      }).then(() => {
        _Toast('消条成功');
        that.$router.go(0);
      });
    },
    afterSafePwdWithNum() {
      if (!this.finish_order_part_num) {
        _Toast('已还部分金额不能为空');
        return;
      }
      if (!this.safePwd) {
        _Toast('交易密码不能为空');
        return;
      }
      let that = this;
      that.showSafePwd = false;
      finishOrderPart({
        sn: this.sn,
        num: this.finish_order_part_num,
        pwd: this.safePwd
      }).then(() => {
        _Toast('部分消条成功');
        that.$router.go(0);
      });
    },
    finish_part_num_click() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      if (that.finish_order_part_num <= 0) {
        _Toast('请正确输入部分金额');
        return;
      }
      this.show_finish_order_part = false;
      that.safePwd = '';
      that.showSafePwd = true;
      // Dialog.confirm({
      // 		title: '部分消条',
      // 		message: '借条将变更借款金额，确定已完成线下交易，确定消条'+that.finish_order_part_num+'吗？',
      // 	})
      // 	.then(() => {
      // 		that.safePwd = ''
      // 		that.showSafePwd = true;
      // 	})
      // 	.catch(() => {
      // 		// on cancel
      // 	});
    },

    applyFinish() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      _Dialog.confirm({
        title: '消条',
        message: '借条将变更为已还款，确定已完成线下交易，确定消条吗？'
      }).then(() => {
        that.safePwd = '';
        that.showSafePwd = true;
      }).catch(() => {
        // on cancel
      });
    },
    applyFinishPart() {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      let that = this;
      _Dialog.confirm({
        title: '消条',
        message: '借条将变更借款金额，确定已完成线下交易，部分消条吗？'
      }).then(() => {
        that.safePwd = '';
        that.showSafePwdWithNum = true;
      }).catch(() => {
        // on cancel
      });
    },
    yanQiTimeConfirm(value) {
      let year = this.currentDate.getFullYear();
      let month = this.currentDate.getMonth() + 1;
      let day = this.currentDate.getDate();
      let diff = this.$dayjs(this.$dayjs(this.currentDate.getTime()).format('YYYY/MM/DD')).diff(this.$dayjs(this.jitiao.end_time * 1000).format('YYYY/MM/DD'), 'day');
      this.yanQiTime = year + '/' + month + '/' + day + '(' + diff + '天)';
      this.yanQiCont = 1;
      this.yanQiDays = diff;
      this.countYanQiFee();
      this.end2 = year + '/' + month + '/' + day;
    },
    //传入天数
    countYanQiFee() {
      let days = this.yanQiDays;
      let index = this.yanQiFeeIndex;
      let tmp = this.jitiao.num * index / 360 * days / 100;
      console.log(tmp);
      let feeSum = tmp + this.jitiao.fee;
      let showTxt = tmp.toFixed(2);
      this.yanQiFee = index + '%,' + showTxt + '元';
      this.feeSum = feeSum.toFixed(2) + '元';
    },
    toYanQi() {
      this.yanQiShow = false;
      this.countYanQiFee();
      this.yanQiDetailShow = true;
    },
    onPickerConfirm(value, index) {
      this.yanQiFeeIndex = index;
      this.yanQiCont = 1;
      this.countYanQiFee();
      return;
      this.fee_year = index;
      let days = this.$dayjs(this.end).diff(this.begin, 'day');
      days++;
      // if(days<1){
      // 	Toast('还款日期小于借款日期')
      // 	return
      // }
      console.log(days);
      let tmp = this.num * index / 360 * days / 100;
      console.log(tmp);
      this.fee_money = tmp.toFixed(2);
      this.fee = value + ',' + this.fee_money + '元';
      this.showPicker = false;
    },
    showHomeBtn() {
      this.showHome = !this.showHome;
    },
    vApplyFinishOrder() {
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '申请还款后，对方同意后，借条交易完成，确定继续吗？'
      }).then(() => {
        applyFinishOrder({
          sn: that.sn
        }).then(() => {
          _Toast("操作成功");
          that.updateStatus(1, 5);
        });
        // Toast("成功")
        // that.status = that.statusArr[4]
        // that.statusTxt = that.statusTxtArr[4]
        // that.jitiao.status = 4
      }).catch(() => {
        // on cancel
      });
    },
    vCancelApplyOrder() {
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '取消申请后，对方无法同意，确定继续吗？'
      }).then(() => {
        applyFinishOrderCancel({
          sn: that.sn
        }).then(() => {
          _Toast("操作成功");
          that.updateStatus(1, 2);
        });
        // Toast("成功")
        // that.status = that.statusArr[4]
        // that.statusTxt = that.statusTxtArr[4]
        // that.jitiao.status = 4
      }).catch(() => {
        // on cancel
      });
    },
    vDelayOrder() {
      let that = this;
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      _Dialog.confirm({
        title: '申请延期',
        message: '申请延期后，对方同意后将变更借条信息，同时生成新的合同，确定延期吗？'
      }).then(() => {
        that.toSelectTime();
        // userCancelOrder({sn:that.sn});  
        // Toast("成功")
        // that.status = that.statusArr[4]
        // that.statusTxt = that.statusTxtArr[4]
        // that.jitiao.status = 4
      }).catch(() => {
        // on cancel
      });
    },
    toSelectTime() {
      this.yanQiShow = true;
    },
    //消条
    finishOrder() {
      let that = this;
      _Dialog.confirm({
        title: '消条',
        message: '申请延期后，对方同意后将变更借条信息，同时生成新的合同，确定延期吗？'
      }).then(() => {
        // userCancelOrder({sn:that.sn});  
        _Toast("成功");
        that.status = that.statusArr[4];
        that.statusTxt = that.statusTxtArr[4];
        that.jitiao.status = 4;
      }).catch(() => {
        // on cancel
      });
    },
    cancelOrder() {
      let that = this;
      _Dialog.confirm({
        title: '提示',
        message: '取消申请后，对方不能确认，借条将无法达成，您确定要关闭该借条吗？'
      }).then(() => {
        userCancelOrder({
          sn: that.sn
        });
        _Toast("取消成功");
        that.updateStatus(4, 99);
      }).catch(() => {
        // on cancel
      });
    },
    init() {
      this.columns = [];
      for (var i = 0; i < 36; i++) {
        this.columns.push(i + "%");
      }
      let that = this;
      // let wxParam = {
      // 	openid: this.uInfo.openid,
      // 	url: that.$domain + this.$route.fullPath
      // }
      // getWxConfig(wxParam).then(resp => {
      // 	let data = resp.data
      // 	let config = {
      // 		debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      // 		appId: 'wx21b455459005e90d', // 必填，公众号的唯一标识
      // 		timestamp: data.timestamp, // 必填，生成签名的时间戳
      // 		nonceStr: data.nonceStr, // 必填，生成签名的随机串
      // 		signature: data.signature, // 必填，签名
      // 		jsApiList: ['chooseWXPay', 'updateAppMessageShareData'] // 必填，需要使用的 JS 接口列表
      // 	}
      // 	console.log(config)
      // 	this.$wx.config(config);
      // })

      this.$wx.ready(function () {
        console.log('wx read');
        // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        that.$wx.updateAppMessageShareData({
          title: '【信而签】,我给你打了一张借条，快来确认吧',
          // 分享标题
          desc: '金额：' + that.num,
          // 分享描述
          link: that.$domain + that.$route.path + '?sn=' + that.sn,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: '',
          // 分享图标
          success: function () {
            // 设置成功
          }
        });
        that.wxReady = true;
        that.vIsGz();
      });
      this.$wx.error(function (res) {
        console.log(res);
        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
      });
    },

    checkWxToken(openid) {
      isHasWxToken({
        openid
      }).then(resp => {
        if (resp.code == 200 && resp.data == 1) {
          this.getData();
        } else {
          this.getCode();
        }
      });
    },
    toPay(type) {
      let that = this;
      let param = {
        'sn': that.sn,
        'type': type,
        'openid': that.uInfo.openid
      };

      //test
      // let upparam = {
      // 	sn: that.sn,
      // 	avatar: that.uInfo.headimgurl
      // }
      // uploadAvatar(upparam);

      // this.updateStatus(1,2);
      // return;

      if (!this.wxReady) {
        this.$router.go(0);
        return;
      }
      getPayConfig(param).then(resp => {
        if (resp.code == 200) {
          // that.status = that.statusArr[1];
          // that.statusTxt = that.statusTxtArr[1];
          // that.pay_time = 1;
          let data = resp.data;
          that.$wx.chooseWXPay({
            timestamp: data.timeStamp,
            // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: data.nonceStr,
            // 支付签名随机串，不长于 32 位
            package: data.package,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.signType,
            // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: data.paySign,
            // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log('pay success');
              // let upparam = {
              // 	sn: that.sn,
              // 	avatar: that.uInfo.headimgurl
              // }
              // uploadAvatar(upparam);

              that.updateStatus(1, 2);
            }
          });
        }
      });
    },
    toPayForYanQi() {
      let that = this;
      if (!that.wxReady) {
        that.$router.go(0);
        return;
      }
      let param = {
        'sn': that.sn,
        'type': 1,
        //延期
        'openid': that.uInfo.openid
      };
      getPayConfig(param).then(resp => {
        if (resp.code == 200) {
          // that.status = that.statusArr[1];
          // that.statusTxt = that.statusTxtArr[1];
          // that.pay_time = 1;
          let data = resp.data;
          that.$wx.chooseWXPay({
            timestamp: data.timeStamp,
            // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: data.nonceStr,
            // 支付签名随机串，不长于 32 位
            package: data.package,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.signType,
            // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: data.paySign,
            // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log('pay success');
              that.$router.go(0);
            }
          });
        }
      });
    },
    rejectTiao() {
      //拒绝
      if (!this.uInfo.phone) {
        this.$router.push('login');
      } else {
        if (!this.uInfo.isMing) {
          this.$router.push('shiming');
          return;
        }
        if (this.who == 1) {
          if (this.uInfo.name != this.jitiao.borrow) {
            _Toast.fail('实名信息不一致！');
            return;
          }
        } else {
          if (this.uInfo.name != this.jitiao.name) {
            _Toast.fail('实名信息不一致！');
            return;
          }
        }
        let that = this;
        _Dialog.confirm({
          title: '提示',
          message: '拒绝申请后，借条将无法达成，您确定要关闭该借条吗？'
        }).then(() => {
          userCancelOrder({
            sn: that.sn
          });
          _Toast("取消成功");
          that.updateStatus(4, 99);
        }).catch(() => {
          // on cancel
        });
      }
    },
    updateStatus(status, opStatus, yuqi) {
      console.log('update status');
      console.log(status);
      console.log(opStatus);
      this.status = this.statusArr[status];
      if (opStatus == 52) {
        this.statusTxt = '拒绝延期';
      } else if (opStatus == 5) {
        this.statusTxt = '还款待确认';
      } else if (opStatus == 3 || opStatus == 4) {
        this.statusTxt = '延期待确认';
      } else {
        this.statusTxt = this.statusTxtArr[status];
      }
      if (status == 1 && yuqi > 0) {
        this.statusTxt = '距收款' + yuqi + '天';
      } else if (status == 1 && yuqi == 0) {
        this.statusTxt = '今日还款';
      } else if (status == 2) {
        this.statusTxt = '逾期' + -yuqi + '天';
      }
      this.opStatus = opStatus;
    },
    //出借人同意无需支付
    rememberNoPay() {
      let that = this;
      if (!getToken()) {
        this.$router.push('login');
      } else {
        if (!this.uInfo.isMing) {
          this.$router.push('shiming');
          return;
        }
        if (this.uInfo.name != this.jitiao.borrow) {
          _Toast.fail('实名信息不一致！');
          return;
        }
        _Dialog.confirm({
          title: '确认',
          message: '确认同意吗？'
        }).then(() => {
          let param = {
            sn: this.sn,
            avatar: this.uInfo.headimgurl
          };
          agreeOrder(param).then(() => {
            _Toast('操作成功');
            that.$router.go(0);
            //this.updateStatus(1, 2);
          });
        }).catch(() => {
          // on cancel
        });
      }
    },
    remember() {
      //同意

      //未登录
      if (!getToken()) {
        this.$router.push('login');
        return;
      } else {
        //先从store 判断
        if (this.$store.getters.isMing) {
          console.log('store ming');
        } else if (!this.uInfo.isMing) {
          console.log('loc ming');
          this.$router.push('shiming');
          return;
        } else {
          console.log('else ming');
        }
        // console.log(this.$store.getters.isMing)
        // return;

        if (this.uInfo.name != this.jitiao.name) {
          _Toast.fail('实名信息不一致！');
          return;
        }
        this.toPay();
        return;
        if (!this.jitiao.pay_time) {
          this.toPay();
          return;
        }
        _Dialog.confirm({
          title: '确认',
          message: '确认同意吗？'
        }).then(() => {
          // on confirm
          _Toast('操作成功');
        }).catch(() => {
          // on cancel
        });
      }
    },
    /**
     * @description: 获取授权code
     * @param {*}
     * @return {*}
     */
    getCode() {
      // 从 window.location.href 中截取 code 并且赋值
      // window.location.href.split('#')[0]
      if (window.location.href.indexOf("state") !== -1) {
        // this.code = qs.parse(
        // 	window.location.href.split("#")[0].split("?")[1]
        // ).code;
        this.code = this.$route.query.code;
      }
      if (this.code) {
        // 存在 code 直接调用接口
        this.handGetUserInfo(this.code);
      } else {
        this.handLogin();
      }
    },
    /**
     * @description: 获取用户授权登陆
     * @param {*}
     * @return {*}
     */
    handLogin() {
      console.log('handLogin');
      let appid = 'wx21b455459005e90d';
      let hrefUrl = window.location.href;
      console.log(`
					https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}
				                       &redirect_uri=${encodeURIComponent(hrefUrl)}
				                       &response_type=code
				                       &scope=snsapi_userinfo
				                       &state=h5#wechat_redirect`);
      // 重定向地址重定到当前页面，在路径获取 code

      // hrefUrl = 'https://tiao.dingshenghuijin.cc/index/getTestCode'

      if (this.code === "") {
        console.log('replace');
        window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_userinfo&state=h5#wechat_redirect`);
      }
    },
    /**
     * @description: 获取用户信息
     * @param {*} code
     * @return {*}
     */
    handGetUserInfo(code) {
      // 调用后台接口
      let param = {
        code
      };
      getWxUserInfo(param).then(resp => {
        if (resp.code == 200) {
          this.uInfo = Object.assign(this.uInfo, resp.data);
          localStorage.setItem("xinguserInfo", JSON.stringify(this.uInfo));
          this.getData();
        }
      });
    },
    shareFriend() {},
    toFriend() {},
    getData() {
      let that = this;
      let params = {
        sn: this.sn
      };
      getInfo(params).then(resp => {
        if (resp.code == 200) {
          var _data$pay_time;
          let data = resp.data;
          this.jitiao = data;
          //本地展示，如果未登录则展示需要操作
          if (data.status == 0 || data.status == 1) {
            var _data$op_status;
            this.opStatus = (_data$op_status = data.op_status) !== null && _data$op_status !== void 0 ? _data$op_status : 0;
            let phone = that.uInfo.phone;
            // phone=null
            if (phone) {
              if (phone == this.jitiao.phone) {
                //借钱
                this.who = 0;
              } else if (phone == this.jitiao.phone_borrow) {
                //出钱
                this.who = 1;
              } else if (!this.jitiao.phone) {
                this.who = 0;
              } else if (!this.jitiao.phone_borrow) {
                this.who = 1;
              } else {
                this.who = 0;
              }
            } else {
              if (!this.jitiao.phone) {
                //借钱
                this.who = 0;
              } else if (!this.jitiao.phone_borrow) {
                this.who = 1;
              } else {
                //出钱
                this.who = 0;
              }
              if (data.op_status == 5 || data.op_status == 3) {
                this.who = 1;
              }
            }
            if (this.who == 0) {
              this.statusArr[1] = '待还款';
            }
          } else {
            this.opStatus = 99;
          }
          that.updateStatus(data.status, this.opStatus);

          //判断是否逾期
          // data.end_time=1676044800
          // let yuqi = this.$dayjs().format('YYYY-MM-DD').diff(this.$dayjs(data.end_time*1000).format('YYYY-MM-DD'),'day')
          if (data.status == 0 || data.status == 1) {
            let yuqi = that.$dayjs(data.end_time * 1000).diff(this.$dayjs(), 'day');
            console.error(yuqi);
            if (yuqi == 0) {
              yuqi = this.$dayjs(this.$dayjs(data.end_time * 1000 + 86400000).format("YYYY-MM-DD")).diff(this.$dayjs(), 'day');
              console.error(yuqi);
              if (yuqi == 1) yuqi = 2;
            } else if (yuqi > 0) {
              yuqi = yuqi + 2;
            }
            //yuqi = yuqi + 2
            if (yuqi < 0) {
              this.updateStatus(2, this.opStatus, yuqi);
            } else {
              that.updateStatus(data.status, this.opStatus, yuqi);
            }
          }

          // dayjs(endTime).diff(dayjs(startTime), 'days')
          // console.error(yuqi)

          console.log(this.jitiao.status);
          console.log(this.opStatus);
          console.log(this.who);
          if (data.op_status == 3 || data.op_status == 4) {
            this.vDelayTime = that.$dayjs(data.delay_time * 1000).format("YYYY-MM-DD");
            this.vSumFee = (data.delay_fee / 100).toFixed(2) + '元';
          }
          if (data.deal_time) {
            this.deal_time = that.$dayjs(data.deal_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          }
          if (data.status == 3 && data.finish_time) {
            this.finish_time = that.$dayjs(data.finish_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          }
          that.yanQiTime = that.$dayjs(data.end_time * 1000).add(30, 'day').format("YYYY-MM-DD");
          that.end2 = that.yanQiTime;
          that.yanQiFee = '0%,0元';
          let year = that.$dayjs(data.end_time * 1000).add(30, 'day').format("YYYY");
          let month = that.$dayjs(data.end_time * 1000).add(30, 'day').format("MM");
          let day = that.$dayjs(data.end_time * 1000).add(30, 'day').format("DD");
          that.currentDate = new Date(year, month - 1, day), that.fee = (data.fee / 100).toFixed(2) + '元';
          that.name = data.name;
          that.note = data.note;
          that.borrow = data.borrow;
          that.num = data.num + '元';
          that.begin = that.$dayjs(data.begin_time * 1000).format("YYYY-MM-DD");
          that.end = that.$dayjs(data.end_time * 1000).format("YYYY-MM-DD");
          that.create_time = data.create_time;
          that.pay_time = (_data$pay_time = data.pay_time) !== null && _data$pay_time !== void 0 ? _data$pay_time : 0;
          that.reason = that.reasonArr[data.reason];
          that.resolution_way = that.resolution_wayArr[data.resolution_way];
          if (data.avatar) {
            that.img1 = data.avatar;
          } else {
            console.log(that.isShare);
            if (that.isShare == 1) {
              that.img1 = this.uInfo.headimgurl;
            }
          }
          if (data.avatar_borrow) {
            that.imgB = data.avatar_borrow;
          } else {
            if (that.isShare == 1) {
              that.imgB = this.uInfo.headimgurl;
            }
          }
          if (data.status == 0) {
            that.showCancelDay = 2 - that.$dayjs().diff(this.$dayjs(data.create_time), 'days');
            if (that.showCancelDay < 3) {
              that.showCancel = true;
            }
          }
          if (data.borrow == that.uInfo.name) {
            that.chu_color = '#f00';
          }
          // data.status =2
          // that.status = that.statusArr[data.status];
          // that.statusTxt = that.statusTxtArr[data.status];

          this.init();
        } else {
          _Toast(resp.message);
          this.$route.go(-1);
        }
      });

      // this.vIsGz();
    }
  }
};