import { getQuestionList } from "@/api/rule";
export default {
  components: {},
  data() {
    return {
      qList: [],
      show: true
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getQuestionList().then(resp => {
        if (resp.code == 200) {
          this.qList = resp.data;
        }
      });
    }
  }
};