import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import img1 from '@/assets/renz/renz_img1.jpg';
import { postSafePwd, getCodeByType } from '@/api/user';
export default {
  components: {
    [_Dialog.Component.name]: _Dialog.Component
  },
  created() {
    let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
    this.value3 = uInfo.phone;
  },
  data() {
    return {
      img1,
      countDownNum: 60,
      timerTf: true,
      timer: null,
      username: '',
      value4: '',
      value5: '',
      value6: '',
      value1: '',
      value2: '',
      value3: '',
      pattern: /\d{17}([0-9]|x|X)/
    };
  },
  methods: {
    receiveCode() {
      let params = {
        phone: this.value3,
        type: 'changesafepwd'
      };
      getCodeByType(params).then(resp => {
        if (resp.code == 200) {
          _Toast('发送成功');
          this.timerTf = false;
          this.countDownNum = 60;
          this.timer = setInterval(() => {
            this.countDownNum--;
            if (this.countDownNum <= 0) {
              this.timerTf = true;
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          _Toast('提示内容');
        }
      });
    },
    validator(val) {
      return /1\d{10}/.test(val);
    },
    onSubmit(values) {
      let that = this;
      if (values.pwd != values.pwd2) {
        _Toast('两次密码不致');
        return;
      }
      console.log('submit', values);
      _Dialog.confirm({
        title: '提示',
        message: '请确认填写的姓名，身份证号为您本人信息，且真实有效'
      }).then(() => {
        // on confirm
        let postData = values;
        postSafePwd(postData).then(resp => {
          _Toast('修改成功');
          that.$router.go(-1);
        });
      }).catch(() => {
        // on cancel
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    }
  }
};