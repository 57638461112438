import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { postFeedback } from '@/api/rule';
export default {
  data() {
    return {
      cont: '',
      password: ''
    };
  },
  methods: {
    onSubmit(values) {
      let that = this;
      postFeedback(values).then(resp => {
        _Toast("提交成功,感谢您的反馈");
        that.$router.go(-1);
      });
      console.log('submit', values);
    }
  }
};