import "core-js/modules/es.array.push.js";
export default {
  watch: {
    'tel': {
      handler(newVal, oldVal) {
        if (newVal.length == 11) {
          this.btnStatus = false;
        } else {
          this.btnStatus = true;
        }
      }
      // deep: true,
      // immediate: true
    }
  },

  data() {
    return {
      btnStatus: true,
      tel: ''
    };
  },
  methods: {
    goNext() {
      this.$router.push('loginpwd?phone=' + this.tel);
    }
    // submit(){
    // 	this.$store.dispatch('user/login', this.loginForm).then(() => {
    // 	  this.$router.push({ path: this.redirect || '/' })
    // 	  this.loading = false
    // 	}).catch(() => {
    // 	  this.loading = false
    // 	})
    // }
  }
};