import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/admin/user/login',
    method: 'post',
    data
  })
}
export function postFeedback(data) {
  return request({
    url: '/index/feedback',
    method: 'post',
    data
  })
}

export function getInvoicingContractList(params) {
  return request({
    url: '/user/msg',
    method: 'get',
    params:params
  })
}
export function getInfo(params) {
  return request({
    url: '/rule/info',
    method: 'get',
    params
  })
}
export function getQuestionList(params) {
  return request({
    url: '/question/list',
    method: 'get',
    params
  })
}
export function getQuestionInfo(params) {
  return request({
    url: '/question/info',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: '/admin/admin/logout',
    method: 'post'
  })
}
