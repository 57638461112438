var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('van-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "type": "textarea",
      "show-word-limit": "",
      "maxlength": "100",
      "name": "cont",
      "placeholder": "请写下您的建议:",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.cont,
      callback: function ($$v) {
        _vm.cont = $$v;
      },
      expression: "cont"
    }
  }), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };