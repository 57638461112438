var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('van-cell', {
    attrs: {
      "title": "账户与安全",
      "is-link": "",
      "to": "safe"
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-top": "0.2125rem"
    },
    attrs: {
      "title": "常见问题",
      "is-link": "",
      "to": "question"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "在线客服",
      "is-link": "",
      "url": "https://kf.dingshenghuijin.cc/kefu/5c6cbcb7d55ca"
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-top": "0.2125rem"
    },
    attrs: {
      "title": "意见反馈",
      "is-link": "",
      "to": "feedback"
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-bottom": "0.9375rem"
    },
    attrs: {
      "title": "关于",
      "is-link": "",
      "to": "about"
    }
  }), _c('van-cell', {
    staticStyle: {
      "margin-bottom": "0.9375rem"
    },
    attrs: {
      "title": "注销账户",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.vXiaoFu();
      }
    }
  }), _c('van-button', {
    staticStyle: {
      "width": "96%",
      "margin-left": "2%"
    },
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("退出登录")]), _c('van-action-sheet', {
    attrs: {
      "title": "交易密码"
    },
    model: {
      value: _vm.showSafePwd,
      callback: function ($$v) {
        _vm.showSafePwd = $$v;
      },
      expression: "showSafePwd"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('van-form', [_c('van-cell-group', [_c('van-field', {
    attrs: {
      "label": "",
      "placeholder": "请输入交易密码",
      "type": "password"
    },
    model: {
      value: _vm.safePwd,
      callback: function ($$v) {
        _vm.safePwd = $$v;
      },
      expression: "safePwd"
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.afterSafePwd();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };