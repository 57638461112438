import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.push.js";
import { getLoginCode } from '@/api/user';
export default {
  watch: {
    'tel': {
      handler(newVal, oldVal) {
        if (newVal.length > 5) {
          this.btnStatus = false;
        } else {
          this.btnStatus = true;
        }
      }
      // deep: true,
      // immediate: true
    },

    'code': {
      handler(newVal, oldVal) {
        if (newVal.length > 5) {
          this.btnStatus = false;
        } else {
          this.btnStatus = true;
        }
      }
      // deep: true,
      // immediate: true
    }
  },

  data() {
    return {
      code: '',
      phone: '',
      loginBy: true,
      countDownNum: 60,
      timerTf: true,
      timer: null,
      otherTex: '验证码登录',
      btnStatus: true,
      tel: '',
      loading: false,
      redirect: ''
    };
  },
  created() {
    this.phone = this.$route.query.phone;
  },
  methods: {
    receiveCode() {
      let that = this;
      let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
      let params = {
        phone: this.phone,
        openid: uInfo.openid
      };
      getLoginCode(params).then(resp => {
        console.log(resp);
        if (resp.code == 200) {
          _Toast('发送成功');
          that.code = resp.data;
          console.log(that.code);
          this.timerTf = false;
          this.countDownNum = 60;
          this.timer = setInterval(() => {
            this.countDownNum--;
            if (this.countDownNum <= 0) {
              this.timerTf = true;
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          _Toast('提示内容');
        }
      });
    },
    changeLogin() {
      this.loginBy = !this.loginBy;
    },
    submit() {
      let loginForm = {
        phone: this.phone,
        password: this.tel,
        loginBy: this.loginBy,
        code: this.code
      };
      this.loading = true;
      if (this.loginBy) {
        this.$store.dispatch('user/login', loginForm).then(resp => {
          // this.$router.push({
          // 	path: this.redirect || '/'
          // })
          let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
          uInfo.phone = loginForm.phone;
          uInfo.isMing = resp.isMing;
          uInfo.name = resp.name;
          localStorage.setItem("xinguserInfo", JSON.stringify(uInfo));
          this.$router.go(-2);
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$store.dispatch('user/loginSms', loginForm).then(resp => {
          // this.$router.push({
          // 	path: this.redirect || '/'
          // })
          console.log(resp);
          let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
          uInfo.phone = loginForm.phone;
          uInfo.isMing = resp.isMing;
          uInfo.name = resp.name;
          localStorage.setItem("xinguserInfo", JSON.stringify(uInfo));
          this.loading = false;
          if (resp.pwd) {
            // this.$router.go(-2)
            this.$router.push('/');
          } else {
            this.$router.push('loginsms');
          }
        }).catch(() => {
          this.loading = false;
        });
      }
    }
  }
};