var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "top-bg2"
  }, [_c('div', {
    staticClass: "top-bg"
  }, [_c('div', {
    class: {
      btnAction: _vm.type == 1,
      unbtnAction: _vm.type == 2
    },
    on: {
      "click": function ($event) {
        return _vm.changeType(1);
      }
    }
  }, [_vm._v("借入")]), _c('div', {
    class: {
      btnAction: _vm.type == 2,
      unbtnAction: _vm.type == 1
    },
    on: {
      "click": function ($event) {
        return _vm.changeType(2);
      }
    }
  }, [_vm._v("借出")])]), _c('van-tabs', {
    staticClass: "tabs",
    attrs: {
      "color": "#FFFFFF",
      "background": "#4098fc",
      "type": "line",
      "title-inactive-color": "#ddd",
      "title-active-color": "#ffffff"
    },
    on: {
      "change": _vm.tabChange
    },
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    attrs: {
      "title": "待确认"
    }
  }), _vm.type == 1 ? _c('van-tab', {
    attrs: {
      "title": "待还款"
    }
  }) : _c('van-tab', {
    attrs: {
      "title": "待收款"
    }
  }), _c('van-tab', {
    attrs: {
      "title": "已逾期"
    }
  }), _c('van-tab', {
    attrs: {
      "title": "已收款"
    }
  }), _c('van-tab', {
    attrs: {
      "title": "已失效"
    }
  })], 1), _vm.type == 2 ? _c('van-search', {
    attrs: {
      "show-action": "",
      "placeholder": "请输入搜索姓名"
    },
    on: {
      "search": _vm.onSearch,
      "cancel": _vm.onCancel
    },
    model: {
      value: _vm.keyword,
      callback: function ($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "tabs",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('van-row', {
    staticStyle: {
      "padding-top": "0.3rem",
      "padding-bottom": "0.3rem"
    }
  }, [_c('van-col', {
    class: {
      select: _vm.sort == 0
    },
    attrs: {
      "span": "6"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSort(0);
      }
    }
  }, [_vm._v("综合")]), _c('van-col', {
    class: {
      select: _vm.sort == 1
    },
    attrs: {
      "span": "6"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSort(1);
      }
    }
  }, [_vm._v("金额 "), _vm.sort == 1 && _vm.moneySortDesc == 1 ? _c('van-icon', {
    attrs: {
      "name": "down"
    }
  }) : _vm._e(), _vm.sort == 1 && _vm.moneySortDesc == 0 ? _c('van-icon', {
    attrs: {
      "name": "back-top"
    }
  }) : _vm._e()], 1), _c('van-col', {
    class: {
      select: _vm.sort == 2
    },
    attrs: {
      "span": "6"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSort(2);
      }
    }
  }, [_vm._v("还款时间 "), _vm.sort == 2 && _vm.timeSortDesc == 1 ? _c('van-icon', {
    attrs: {
      "name": "down"
    }
  }) : _vm._e(), _vm.sort == 2 && _vm.timeSortDesc == 0 ? _c('van-icon', {
    attrs: {
      "name": "back-top"
    }
  }) : _vm._e()], 1), _c('van-col', {
    attrs: {
      "span": "6"
    }
  })], 1), _vm.show ? _c('van-loading', {
    staticStyle: {
      "padding-top": "1rem"
    },
    attrs: {
      "type": "spinner"
    }
  }) : _vm._e(), !_vm.show ? _c('div', {
    staticStyle: {
      "background-color": "#f1f2f6",
      "padding-top": "1px",
      "padding-bottom": "0.5rem",
      "height": "60vh"
    }
  }, [_vm.noData ? _c('div', {
    staticClass: "p30 text-center fontSize30"
  }, [_c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  })], 1) : _vm._e(), _c('van-pull-refresh', {
    staticClass: "content",
    attrs: {
      "success-text": "刷新成功"
    },
    on: {
      "refresh": _vm.onRefresh
    },
    model: {
      value: _vm.isLoading,
      callback: function ($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  }, [_vm.contractData.length > 0 ? _c('van-list', {
    attrs: {
      "finished": _vm.finished,
      "immediate-check": false,
      "finished-text": "我也是有底线的",
      "offset": 10
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.contractData, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "van-hairline--left item"
    }, [_c('router-link', {
      attrs: {
        "to": `orderinfo?sn=${item.sn}`
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      }
    }, [_vm.type == 2 ? _c('van-image', {
      staticStyle: {
        "margin-left": "0.2rem",
        "margin-right": "0.2rem"
      },
      attrs: {
        "round": "",
        "width": "0.6rem",
        "height": "0.6rem",
        "src": item.avatar
      }
    }) : _c('van-image', {
      staticStyle: {
        "margin-left": "0.2rem",
        "margin-right": "0.2rem"
      },
      attrs: {
        "round": "",
        "width": "0.6rem",
        "height": "0.6rem",
        "src": item.avatar_borrow
      }
    }), _vm.type == 2 ? _c('span', [_vm._v(_vm._s(item.name))]) : _c('span', [_vm._v(_vm._s(item.borrow))]), _c('span', {
      staticStyle: {
        "flex-grow": "1",
        "text-align": "right"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.timeCompute(item.end_time))
      }
    })], 1), _c('van-divider'), _c('div', {
      staticStyle: {
        "display": "flex",
        "margin-top": "0.3rem"
      }
    }, [_c('div', {
      staticStyle: {
        "flex-grow": "1"
      }
    }, [_c('div', [_vm._v("金额:" + _vm._s(item.num))]), _c('div', {
      staticStyle: {
        "margin-top": "0.2rem"
      }
    }, [_vm._v("利息:" + _vm._s(item.fee / 100))])]), _c('div', {
      staticStyle: {
        "flex-grow": "1",
        "text-align": "right"
      }
    }, [_c('div', [_vm._v("开始日期:" + _vm._s(_vm.$dayjs(item.begin_time * 1000).format("YYYY-MM-DD")))]), _c('div', {
      staticStyle: {
        "margin-top": "0.2rem"
      }
    }, [_vm._v(" 结束日期:" + _vm._s(_vm.$dayjs(item.end_time * 1000).format("YYYY-MM-DD")))])])])], 1), item.status == 3 && _vm.type == 2 ? _c('div', {
      staticStyle: {
        "text-align": "right",
        "padding": "0.2rem 0"
      }
    }, [_c('van-button', {
      attrs: {
        "type": "info",
        "size": "mini"
      },
      on: {
        "click": function ($event) {
          return _vm.vDelTiao(item.id);
        }
      }
    }, [_vm._v("删除借条")])], 1) : item.status == 4 ? _c('div', {
      staticStyle: {
        "text-align": "right",
        "padding": "0.2rem 0"
      }
    }, [_c('van-button', {
      attrs: {
        "type": "info",
        "size": "small"
      },
      on: {
        "click": function ($event) {
          return _vm.vDelTiao(item.id);
        }
      }
    }, [_vm._v("删除借条")])], 1) : _vm._e()], 1);
  }), 0) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('van-tabbar', {
    attrs: {
      "route": ""
    }
  }, [_c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/",
      "icon": "home-o"
    }
  }, [_vm._v("首页")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/orderlist",
      "icon": "after-sale"
    }
  }, [_vm._v("合同")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/msg",
      "icon": "chat-o"
    }
  }, [_vm._v("消息")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/my",
      "icon": "user-o"
    }
  }, [_vm._v("我的")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };