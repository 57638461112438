import "core-js/modules/es.array.push.js";
export default {
  components: {},
  data() {
    return {
      show: true,
      hasName: '已认证',
      hasBank: '已绑定',
      hasMail: '已绑定',
      phone: '139****8888'
    };
  },
  created() {
    let tmp = localStorage.getItem("xinguserInfo");
    if (tmp) {
      let uInfo = JSON.parse(tmp);
      let phone = uInfo.phone;
      let str = phone.substring(0, 3) + "****" + phone.substring(7);
      this.phone = str;
      if (!uInfo.isMing) {
        this.hasName = '未认证';
        this.hasBank = '未认证';
        this.hasMail = '未认证';
      }
    }
  },
  methods: {
    vRenZhen() {
      if (this.hasName == '未认证') {
        this.$router.push('shiming');
      }
    }
  }
};