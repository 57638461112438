var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cont"
  }, [_c('van-pull-refresh', {
    on: {
      "refresh": _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function ($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished,
      "finished-text": "没有更多了"
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "list"
    }, [_c('div', {
      staticClass: "idNumber"
    }, [_c('div', {
      staticClass: "left-id"
    }, [_vm._v("12345664")]), _c('div', {
      staticClass: "time"
    }, [_vm._v("2023/08/12")])]), _c('div', {
      staticClass: "idNumber m-t"
    }, [_c('div', {
      staticClass: "left-id"
    }, [_vm._v("签约状态:"), _c('van-tag', {
      attrs: {
        "type": "danger"
      }
    }, [_vm._v("未签约")])], 1), _c('div', {
      staticClass: "time"
    }, [_vm._v("支付状态:"), _c('van-tag', {
      attrs: {
        "type": "danger"
      }
    }, [_vm._v("未支付")])], 1)])]);
  }), 0)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };