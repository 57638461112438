import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { setPwd } from '@/api/user';
export default {
  watch: {
    //  'tel': {
    //      handler(newVal, oldVal) {
    //          console.log(newVal, oldVal)
    // if (newVal.length==11) {
    // 	this.btnStatus = false
    // }else{
    // 	this.btnStatus = true
    // }
    //      },
    //      // deep: true,
    //      // immediate: true
    //  }
  },
  data() {
    return {
      btnStatus: true,
      pwd: '',
      pwd2: ''
    };
  },
  methods: {
    pwdTest() {
      if (this.pwd.length > 6) return false;
      return true;
    },
    submit() {
      let that = this;
      if (!this.pwd || !this.pwd2) {
        _Toast('请输入密码');
        return;
      }
      if (this.pwd.length < 6 || this.pwd.length > 20) {
        _Toast('密码长度6-20位');
        return;
      }
      if (this.pwd != this.pwd2) {
        _Toast('两次密码不一致');
        return;
      }
      let pram = {
        password: this.pwd
      };
      setPwd(pram).then(resp => {
        that.$router.go(-3);
      });
    }
  }
};