var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.avatar,
      "round": "",
      "width": "1.2rem",
      "height": "1.2rem"
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0.5rem"
    }
  }, [_c('div', [_c('span', {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "0.8rem"
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c('span', {
    staticStyle: {
      "font-size": "0.5rem",
      "padding-left": "0.5rem"
    }
  })]), _c('div', [_c('van-button', {
    staticStyle: {
      "padding": "0 0.3rem"
    },
    attrs: {
      "plain": "",
      "round": "",
      "type": "info",
      "size": "mini"
    }
  }, [_vm._v("实名")])], 1)])], 1), _c('div', {
    staticClass: "ditem"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "xiao"
  }, [_vm._v("借入明细")]), _c('span', {
    staticClass: "time",
    on: {
      "click": function ($event) {
        return _vm.vSelectTime(0);
      }
    }
  }, [_vm._v(_vm._s(_vm.ditemTime1))]), _c('van-icon', {
    attrs: {
      "name": "play"
    }
  })], 1), _c('van-row', {
    staticClass: "itemtitle"
  }, [_c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("类型")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("个数")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("金额（元）")])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("逾期待还")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[0][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[0][1]))])], 1), _c('van-row', {
    staticClass: "itemtext2"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("未逾期待还")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[1][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[1][1]))])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("逾期已还")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[2][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[2][1]))])], 1), _c('van-row', {
    staticClass: "itemtext2"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("按时还款")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[3][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[3][1]))])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("申请延期")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[4][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr1[4][1]))])], 1)], 1), _c('div', {
    staticClass: "ditem"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "xiao"
  }, [_vm._v("逾期记录")]), _c('span', {
    staticClass: "time",
    on: {
      "click": function ($event) {
        return _vm.vSelectTime(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.ditemTime2))]), _c('van-icon', {
    attrs: {
      "name": "play"
    }
  })], 1), _c('van-row', {
    staticClass: "itemtitle"
  }, [_c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("类型")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("个数（%）")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("金额（%）")])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("逾期占比")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[0][0]) + "%")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[0][1]) + "%")])], 1), _c('van-row', {
    staticClass: "itemtext2"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("逾期已还占比")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[1][0]) + "%")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[1][1]) + "%")])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("逾期未还占比")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[2][0]) + "%")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr2[2][1]) + "%")])], 1)], 1), _c('div', {
    staticClass: "ditem"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "xiao"
  }, [_vm._v("借贷统计")]), _c('span', {
    staticClass: "time",
    on: {
      "click": function ($event) {
        return _vm.vSelectTime(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.ditemTime3))]), _c('van-icon', {
    attrs: {
      "name": "play"
    }
  })], 1), _c('van-row', {
    staticClass: "itemtitle"
  }, [_c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("类型")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("借入")]), _c('van-col', {
    staticClass: "kuang",
    attrs: {
      "span": "8"
    }
  }, [_vm._v("借出")])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("累计笔数")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[0][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[0][1]))])], 1), _c('van-row', {
    staticClass: "itemtext2"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("累计金额")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[1][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[1][1]))])], 1), _c('van-row', {
    staticClass: "itemtext"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v("最大金额")]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[2][0]))]), _c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.arr3[2][1]))])], 1)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions,
      "cancel-text": "取消",
      "close-on-click-action": "",
      "description": "请选择日期"
    },
    on: {
      "select": _vm.onSelect
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };