var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground",
    style: _vm.background
  }, [_c('div', {
    staticClass: "contbg"
  }, [_c('div', {
    staticClass: "contbg2"
  }, [_c('div', {
    staticStyle: {
      "text-align": "right",
      "top": "-28px",
      "position": "relative",
      "right": "-30px"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "80px",
      "height": "66px",
      "src": _vm.img1
    }
  })], 1), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(61);
      }
    }
  }, [_vm._v(" 外包合同通用版 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于一般事项外包场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(62);
      }
    }
  }, [_vm._v(" 方案写作合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于代写文案场景 ")]), _c('van-divider'), _c('div', {
    staticClass: "block-top",
    on: {
      "click": function ($event) {
        return _vm.toEsJy(63);
      }
    }
  }, [_vm._v(" 绘画约稿合同 ")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" 适用于绘画约稿场景 ")]), _c('van-divider')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };