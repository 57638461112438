import { getInfo } from '@/api/msg';
export default {
  name: 'Home',
  title: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      id: 0,
      show: true,
      msg: {},
      cont: ''
    };
  },
  created() {
    var _this$$route$query$id;
    this.id = (_this$$route$query$id = this.$route.query.id) !== null && _this$$route$query$id !== void 0 ? _this$$route$query$id : 1;
    //方法调用
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        id: this.id
      };
      getInfo(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.msg = res.data;
          this.cont = this.msg.content;
        } else {
          this.$toast(res.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    }
  }
};