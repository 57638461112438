var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-image', {
    attrs: {
      "src": _vm.img1
    }
  }), _c('van-form', {
    attrs: {
      "validate-first": ""
    },
    on: {
      "failed": _vm.onFailed,
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "name": "name",
      "label": "姓名",
      "placeholder": "姓名",
      "rules": [{
        required: true,
        message: '请填写姓名'
      }]
    },
    model: {
      value: _vm.value1,
      callback: function ($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('van-field', {
    attrs: {
      "name": "idcard",
      "label": "身份证",
      "placeholder": "身份证",
      "rules": [{
        pattern: _vm.pattern,
        message: '请输入正确身份证'
      }]
    },
    model: {
      value: _vm.value2,
      callback: function ($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('van-field', {
    attrs: {
      "name": "bank_no",
      "label": "银行卡号",
      "placeholder": "银行卡号",
      "rules": [{
        required: true,
        message: '请填写银行卡号'
      }]
    },
    model: {
      value: _vm.value4,
      callback: function ($$v) {
        _vm.value4 = $$v;
      },
      expression: "value4"
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "name": "phone",
      "label": "手机号",
      "placeholder": "银行预留手机号",
      "rules": [{
        validator: _vm.validator,
        message: '请输入正确手机号'
      }]
    },
    model: {
      value: _vm.value3,
      callback: function ($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  }), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "loading": _vm.loading,
      "loading-text": "验证中...",
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("提交 ")])], 1), _c('div', {
    staticStyle: {
      "padding": "0.2rem"
    }
  }, [_c('p', [_vm._v("温馨提示：")]), _c('p', [_vm._v("1、绑定的银行卡的预留手机号需跟当前的登录手机号保持一致")]), _c('p', [_vm._v("2、姓名，身份证信息一经确认不能修改")]), _c('p', [_vm._v("3、手机号不正确？，"), _c('span', {
    staticStyle: {
      "color": "skyblue"
    },
    on: {
      "click": _vm.torelogin
    }
  }, [_vm._v("去修改")])])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };