var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', [_c('van-tabs', {
    attrs: {
      "background": "#3f80fe",
      "color": "#fff",
      "title-active-color": "#fff",
      "title-inactive-color": "#eee"
    },
    on: {
      "click": _vm.onClick
    },
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    staticStyle: {
      "padding-top": "0.2rem",
      "padding-bottom": "0.4rem"
    },
    attrs: {
      "title": "我是借款人"
    }
  }), _c('van-tab', {
    staticStyle: {
      "padding-top": "0.2rem",
      "padding-bottom": "0.4rem"
    },
    attrs: {
      "title": "我是出借人"
    }
  })], 1), _c('van-form', {
    attrs: {
      "input-align": "right"
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_vm.active == 0 ? _c('van-field', {
    attrs: {
      "name": "name",
      "label": "借款人",
      "readonly": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }) : _c('van-field', {
    attrs: {
      "name": "name",
      "label": "出借人",
      "readonly": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('van-field', {
    attrs: {
      "type": "digit",
      "name": "num",
      "label": "借款金额",
      "placeholder": "最低10元"
    },
    model: {
      value: _vm.num,
      callback: function ($$v) {
        _vm.num = $$v;
      },
      expression: "num"
    }
  }), _c('van-field', {
    attrs: {
      "is-link": "",
      "readonly": "",
      "clickable": "",
      "name": "begin_time",
      "value": _vm.begin,
      "label": "开始日期",
      "placeholder": "点击选择日期"
    },
    on: {
      "click": function ($event) {
        return _vm.showTimePicker(0);
      }
    }
  }), _c('van-field', {
    attrs: {
      "is-link": "",
      "readonly": "",
      "clickable": "",
      "name": "end_time",
      "value": _vm.end,
      "label": "还款日期",
      "placeholder": "点击选择日期"
    },
    on: {
      "click": function ($event) {
        return _vm.showTimePicker(1);
      }
    }
  }), _c('van-action-sheet', {
    attrs: {
      "title": ""
    },
    model: {
      value: _vm.showCalendar2,
      callback: function ($$v) {
        _vm.showCalendar2 = $$v;
      },
      expression: "showCalendar2"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日",
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate
    },
    on: {
      "confirm": function ($event) {
        return _vm.timeConfirm();
      },
      "cancel": function ($event) {
        return _vm.timeCancel();
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function ($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)]), _c('van-field', {
    attrs: {
      "is-link": "",
      "readonly": "",
      "clickable": "",
      "name": "fee",
      "value": _vm.fee,
      "label": "借款利息"
    },
    on: {
      "click": function ($event) {
        _vm.showPicker = true;
      }
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPicker,
      callback: function ($$v) {
        _vm.showPicker = $$v;
      },
      expression: "showPicker"
    }
  }, [_c('van-picker', {
    ref: "feePicker",
    attrs: {
      "title": "年化利率",
      "show-toolbar": "",
      "columns": _vm.columns
    },
    on: {
      "confirm": _vm.onPickerConfirm,
      "cancel": function ($event) {
        _vm.showPicker = false;
      }
    }
  })], 1), _c('van-field', {
    attrs: {
      "is-link": "",
      "readonly": "",
      "clickable": "",
      "name": "reason",
      "value": _vm.reason,
      "label": "借款用途"
    },
    on: {
      "click": function ($event) {
        _vm.showPicker2 = true;
      }
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPicker2,
      callback: function ($$v) {
        _vm.showPicker2 = $$v;
      },
      expression: "showPicker2"
    }
  }, [_c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "columns": _vm.columns2
    },
    on: {
      "confirm": _vm.onPickerConfirm2,
      "cancel": function ($event) {
        _vm.showPicker2 = false;
      }
    }
  })], 1), _vm.active == 1 ? _c('van-field', {
    attrs: {
      "is-link": "",
      "readonly": "",
      "clickable": "",
      "name": "resolution_way",
      "value": _vm.ways,
      "label": "争议解决方式"
    },
    on: {
      "click": function ($event) {
        _vm.showPicker3 = true;
      }
    }
  }) : _vm._e(), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPicker3,
      callback: function ($$v) {
        _vm.showPicker3 = $$v;
      },
      expression: "showPicker3"
    }
  }, [_c('van-picker', {
    attrs: {
      "title": "争议解决方式",
      "show-toolbar": "",
      "columns": _vm.columns3
    },
    on: {
      "confirm": _vm.onPickerConfirm3,
      "cancel": function ($event) {
        _vm.showPicker3 = false;
      }
    }
  })], 1), _vm.active == 0 ? _c('van-field', {
    attrs: {
      "name": "name2",
      "label": "找谁借款",
      "placeholder": "真实姓名"
    },
    model: {
      value: _vm.name2,
      callback: function ($$v) {
        _vm.name2 = $$v;
      },
      expression: "name2"
    }
  }) : _c('van-field', {
    attrs: {
      "name": "name2",
      "label": "借款给谁",
      "placeholder": "真实姓名"
    },
    model: {
      value: _vm.name2,
      callback: function ($$v) {
        _vm.name2 = $$v;
      },
      expression: "name2"
    }
  }), _c('van-field', {
    attrs: {
      "maxlength": "50",
      "rows": "1",
      "autosize": "",
      "type": "textarea",
      "name": "note",
      "label": "备注",
      "placeholder": "最多50字"
    },
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  }), _c('div', {
    staticStyle: {
      "background-color": "#eee",
      "display": "flex",
      "padding": "0.3rem",
      "font-size": "0.3rem",
      "align-items": "center"
    }
  }, [_c('van-checkbox', {
    attrs: {
      "shape": "square",
      "icon-size": "0.4rem"
    },
    model: {
      value: _vm.checkbox,
      callback: function ($$v) {
        _vm.checkbox = $$v;
      },
      expression: "checkbox"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "0.2rem",
      "margin-top": "0.1rem"
    }
  }, [_vm._v("已阅读并同意"), _c('router-link', {
    attrs: {
      "to": "rulesample"
    }
  }, [_vm._v("《借款协议》 ")])], 1)], 1), _c('van-button', {
    staticStyle: {
      "width": "96%",
      "left": "2%",
      "position": "fixed",
      "bottom": "0.3rem",
      "right": "2%"
    },
    attrs: {
      "disabled": !_vm.checkbox,
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("提交")])], 1), _c('van-action-sheet', {
    attrs: {
      "title": "交易详情"
    },
    model: {
      value: _vm.actionSheetShow,
      callback: function ($$v) {
        _vm.actionSheetShow = $$v;
      },
      expression: "actionSheetShow"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "交易账户",
      "value": _vm.phone
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付方式",
      "value": _vm.payways[_vm.payindex],
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        _vm.paywayShow = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付金额",
      "value": _vm.vShowMoney
    }
  })], 1), _c('van-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toPay();
      }
    }
  }, [_vm._v("确定支付")])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.paywayShow,
      expression: "paywayShow"
    }],
    staticClass: "content"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "微信支付",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(0);
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "余额支付",
      "value": "余额不足",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changePayWays(1);
      }
    }
  })], 1), _c('van-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.paywayShow,
      expression: "!paywayShow"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.toPay
    }
  }, [_vm._v("确定支付 ")])], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };